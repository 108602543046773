import styled from 'styled-components';
import { Colors, Breakpoints, H2 } from '../index';
import hero from './background.jpg';

export const AdvertisementStyled = styled.div`
  overflow: hidden;
  border-radius: 20px;
  margin-bottom: 20px;
  position: relative;
  min-height: 250px;
  background-image: url("${hero}");
`;

export const ImgStyled = styled.img`
  width: 100%;
  min-height: 100%;
  position: absolute;
`;

export const ContentStyled = styled.div`
  z-index: 10;
  color: ${Colors.white};
  padding: 20px;
  ${Breakpoints.medium} {
    padding-left: 40px;
  }
`;

export const TitleStyled = styled.div`
  z-index: 10;
  font-weight: 600;
`;

export const ReasonTextStyled = styled(H2)`
  color: ${Colors.primaryColor};
  margin: 10px 0;
`;

export const BenefitTextStyled = styled.div`
  font-weight: 300;
  margin: 20px 0;
`;
