import { action, observable } from 'mobx';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { UserApi } from '../api';
import { logger } from '../../../shared';

export class UserPageStore {
  @observable.shallow users = [];

  @observable totalUser = 0;

  @observable currentPage = 1;

  @observable pageSize = 15;

  @observable value = '';

  @observable isLoading = true;

  constructor(store) {
    this.store = store;
  }

  reaction() {
    // reaction(
    //   () => this.store.routerStore.location,
    //   ({ pathname }) => {
    //     if (pathname === UserUrls.page) {
    //       this.fetchUsers();
    //     }
    //   },
    // );
  }

  @action.bound
  onChangePage = page => {
    this.currentPage = page;
    this.fetchUsers();
  };

  getStart = () => {
    return this.pageSize * (this.currentPage - 1);
  };

  @action.bound
  fetchUsers(resetPage = false) {
    if (resetPage) {
      this.currentPage = 1;
    }
    const value = !isEmpty(this.value) ? this.value : undefined;
    const size = this.pageSize;
    const start = this.getStart();

    return axios
      .get(UserApi.users(start, size, value), this.store.authStore.getUserAuthorizationHeaders())
      .then(res => res.data)
      .then(
        action('fetch list user success', data => {
          const { users, total } = data;
          this.users = users;
          this.totalUser = total;
        }),
      )
      .catch(
        action('get items fail', error => {
          logger.log(error);
        }),
      );
  }

  @action.bound
  onSearch = value => {
    this.value = value;
    this.fetchUsers(true);
  };
}
