import { stringify } from 'query-string';
import { CONFIGS } from '../../shared/config';

const ROOT_API = CONFIGS.NODE_API_PREFIX;

export const CategoryApis = {
  detail: id => `${ROOT_API}/categories/${id}`,
  search: (start = 0, size = 10, q, types) =>
    `${ROOT_API}/categories/search?${stringify(
      {
        start,
        size,
        q,
        types,
      },
      {
        skipEmptyString: true,
        skipNull: true,
      },
    )}`,
  delete: id => `${ROOT_API}/categories/${id}`,
  suggest: (q, lang, types, start = 0, size = 30) =>
    `${ROOT_API}/categories/search?${stringify({
      start,
      size,
      q,
    })}`,
  create: `${ROOT_API}/categories`,
  update: id => `${ROOT_API}/categories/${id}`,
};
