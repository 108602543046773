import React from 'react';
import Slider from 'react-slick';
import { Container } from '../index';
import { WrapperStyled } from './SlickCarousel.styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const SlickCarousel = ({ settings, items, renderItem }) => {
  return (
    <WrapperStyled>
      <Container>
        <Slider {...settings}>{items.map(item => renderItem(item))}</Slider>
      </Container>
    </WrapperStyled>
  );
};
