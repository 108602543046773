import React from 'react';
import { Icon } from 'antd';
import { Spring, animated } from 'react-spring/renderprops';
import { Wrapper, Item, Items, LeftNav, RightNav, PlaceHolderRight } from './Carousel.styles';

export class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollLeft: 0,
    };
    this.itemsRef = React.createRef();
  }

  onLeftNavClick = () => {
    const { scrollLeft } = this.state;
    this.setScrollLeft(scrollLeft - 200);
  };

  onRightNavClick = () => {
    const { scrollLeft } = this.state;
    this.setScrollLeft(scrollLeft + 200);
  };

  setScrollLeft = inScrollLeft => {
    let scrollLeft = inScrollLeft;
    if (scrollLeft < 0) {
      scrollLeft = 0;
    } else {
      const maxScrollLeft = this.itemsRef.current.scrollWidth - this.itemsRef.current.clientWidth;
      if (scrollLeft > maxScrollLeft) {
        scrollLeft = maxScrollLeft;
      }
    }

    this.setState({
      scrollLeft,
    });
  };

  render() {
    const { scrollLeft } = this.state;
    const from = {
      scrollLeft: this.itemsRef.current ? this.itemsRef.current.scrollLeft : 0,
    };
    const to = {
      scrollLeft,
    };
    const { renderItem, items } = this.props;
    const style = { overflowX: 'scroll' };

    return (
      <Wrapper>
        <Spring native reset from={from} to={to}>
          {props => (
            <animated.div scrollLeft={props.scrollLeft} ref={this.itemsRef} style={style}>
              <Items>
                {items.map(item => (
                  <Item key={item.id}>{renderItem(item)}</Item>
                ))}
                <PlaceHolderRight />
              </Items>
            </animated.div>
          )}
        </Spring>
        <LeftNav onClick={this.onLeftNavClick}>
          <Icon type="left" />
        </LeftNav>
        <RightNav onClick={this.onRightNavClick}>
          <Icon type="right" />
        </RightNav>
      </Wrapper>
    );
  }
}
