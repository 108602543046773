import styled from 'styled-components';
import { Breakpoints } from './Breakpoint.styles';

export const Container = styled.div`
  margin: 0 10px;

  ${Breakpoints.medium} {
    margin: 0 20px;
  }

  ${Breakpoints.xlarge} {
    max-width: 1140px;
    margin: 0 auto;
  }
`;
