import queryString from 'query-string';
import { RouterStore as InternalRouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { computed } from 'mobx';
import { createBrowserHistory } from 'history';

class RouterStore {
  internalRouterStore;

  constructor() {
    this.internalRouterStore = new InternalRouterStore();
  }

  @computed get query() {
    return queryString.parse(this.location.search);
  }

  @computed get location() {
    return this.internalRouterStore.location || {};
  }

  @computed get pathname() {
    return this.location.pathname;
  }

  push = (...args) => {
    this.internalRouterStore.push(...args);
  };

  replace = (...args) => {
    this.internalRouterStore.replace(...args);
  };

  createHistory = () => {
    const browserHistory = createBrowserHistory();
    return syncHistoryWithStore(browserHistory, this.internalRouterStore);
  };
}
export const routerStore = new RouterStore();
