import styled from 'styled-components';
import { Colors } from '../styles/Color.styles';

export const Items = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

export const Item = styled.div`
  flex: 0 0 auto;
  margin: 10px 10px 20px 10px;
`;

const NavStyled = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.primaryColor};
  color: ${Colors.white};
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  font-size: 15px;
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
`;

export const Wrapper = styled.div`
  position: relative;

  :hover {
    ${NavStyled} {
      opacity: 1;
    }
  }
`;

export const LeftNav = styled(NavStyled)`
  left: 0;
`;

export const RightNav = styled(NavStyled)`
  right: 0;
`;

export const PlaceHolderRight = styled.div`
  flex: 0 0 20px;
`;
