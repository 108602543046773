export const decorators = {
  name: 'name',
  title: 'title',
  address: 'address',
  street: 'street',
  zipcode: 'zipcode',
  city: 'city',
  country: 'country',
  description: 'description',
  affiliate: 'affiliate',
  agreePublish: 'agreePublish',
  researchGateRank: 'researchGateRank',
  researchGateCitations: 'researchGateCitations',
  googleCitations: 'googleCitations',
  h_index: 'h_index',
  i10index: 'i10index',
  categories: 'categories',
  status: 'status',
  nameVi: 'nameVi',
  nameEn: 'nameEn',
  nameFr: 'nameFr',
  synonymsVi: 'synonymsVi',
  synonymsEn: 'synonymsEn',
  synonymsFr: 'synonymsFr',
  thumbnail: 'thumbnail',
  types: 'types',
  parentId: 'parentId',
  weight: 'weight',
  business_rank: 'business_rank',
};

export const statusEnum = {
  verified: 'verified',
  unverified: 'unverified',
};

export const languagesEnum = {
  vi: 'vi',
  en: 'en',
  fr: 'fr',
};

export const categoryTypesEnum = {
  business: 'business',
  profession: 'profession',
  field: 'field',
  event: 'event',
  academicRank: 'academic_rank',
  corporateTitle: 'corporate_title',
  gics: 'gics',
  microsoftAcademic: 'microsoft_academic',
  academicDiscipline: 'academicDiscipline',
  technology: 'technology',
};
