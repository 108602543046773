export const Url = {
  home: '/',
  vietSearch: '/entries',
  vietMap: '/viet-map',
  verifyUser: '/user/verify/:token',
  resetPassword: '/user/reset-password/:token',
  login: '/user/login',
  getDetail: id => `/detail/${id}`,
  aboutUs: '/about-us',
  aboutUsMobile: '/mobile/about-us',
  term: '/term',
  termMobile: '/mobile/term',
  privacy: '/privacy',
  privacyMobile: '/mobile/privacy',
  swagger: '/swagger',
  experts: '/experts',
  services: '/services',
  events: '/events',
};
