import styled from 'styled-components';
import { Select } from 'antd';
import { Colors } from '../styles/Color.styles';

export const SelectRoundStyled = styled(Select)`
  .ant-select-selection {
    border-radius: 20px;
    margin-right: 5px;
  }
  .ant-select-selection__placeholder {
    color: ${Colors.primaryColor};
    font-weight: 500;
  }
  .ant-select-dropdown .ant-select-dropdown-menu .ant-select-dropdown-menu-item {
    color: ${Colors.primaryColor};
    font-weight: 500;
  }
`;
