export const getIdFromUrl = path => {
  const id = path.substring(path.lastIndexOf('/') + 1);
  return id || '';
};

export const getStart = (pageSize, currentPage) => {
  return pageSize * (currentPage - 1);
};

export const faceBookShare = url => {
  window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes');
  return false;
};
