import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import { Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { findTypeEnum } from '../../../store/vietsearchStore';

const { Option } = Select;

const InputFindType = ({ value, onChange }) => {
  const [t] = useTranslation(['common']);
  return (
    <Select size="large" value={value} onChange={onChange} style={{ width: 200 }}>
      <Option value={findTypeEnum.all}>{t('findTypeText.findAll')}</Option>
      <Option value={findTypeEnum.expert}>{t('findTypeText.findExpert')}</Option>
      <Option value={findTypeEnum.business}>{t('findTypeText.findBusiness')}</Option>
    </Select>
  );
};

export const InputFindTypeContainer = compose(
  inject(({ store: { vietsearchStore } }) => ({
    value: vietsearchStore.types,
    onChange: vietsearchStore.onFindTypeChanged,
  })),
  observer,
)(InputFindType);
