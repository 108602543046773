import React from 'react';
import { Chart } from 'react-google-charts';

export const UserPerDay = () => {
  return (
    <Chart
      width="100%"
      height="400px"
      chartType="Bar"
      loader={<div>Loading Chart</div>}
      data={[
        ['Day', 'Users'],
        ['20', 500],
        ['21', 1000],
        ['22', 1500],
        ['23', 400],
      ]}
      options={{
        chart: {
          title: 'User Per day',
          subtitle: 'Total user used vietsearch per day',
        },
        hAxis: { title: 'day', titleTextStyle: { color: '#333' } },
        vAxis: { minValue: 0 },
        // For the legend to fit, we make the chart area smaller
        chartArea: { width: '50%', height: '70%' },
        // lineWidth: 25
      }}
      // For tests
      rootProps={{ 'data-testid': '1' }}
    />
  );
};
