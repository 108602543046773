import React from 'react';

export const Html = ({ Tag, content, className }) => {
  const innerHtml = {
    __html: content,
  };
  return <Tag dangerouslySetInnerHTML={innerHtml} className={className} />;
};

Html.defaultProps = {
  Tag: 'div',
};
