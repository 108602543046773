import { action, observable, computed } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import { get } from 'lodash';
import axios from 'axios';
import validator from 'validator';
import vjf from 'mobx-react-form/lib/validators/VJF';
import { openErrorNotification, openSuccessNotification } from '../shared/components';
import { APIS } from './apis';
import { Url } from '../shared';
import i18n from '../shared/components/languages/i18n';

i18n.loadNamespaces('auth');

const plugins = {
  vjf: vjf(),
};

const options = {
  showErrorsOnReset: false,
};

function validateEmail({ field }) {
  if (!field.value) return [false, i18n.t('auth:mustBeEmail')];
  if (!validator.isEmail(field.value)) return [false, i18n.t('auth:mustBeEmail')];
  return true;
}

function validatePassword({ field }) {
  if (!field.value) return [false, i18n.t('auth:mustBePassword')];
  if (field.value.length < 10) return [false, i18n.t('auth:mustBePassword')];
  return true;
}

function validatePasswordConfirm({ field, form }) {
  const pw = form.$('password').value;
  if (!field.value) return [false, i18n.t('auth:mustBePasswordConfirm')];
  if (pw !== field.value) return [false, i18n.t('auth:mustBePasswordConfirm')];
  return true;
}

const loginFields = [
  {
    name: 'email',
    validators: [],
  },
  {
    name: 'password',
    validators: [],
  },
];

const registerFields = [
  {
    name: 'email',
    validators: [validateEmail],
  },
  {
    name: 'password',
    validators: [validatePassword],
  },
  {
    name: 'passwordConfirm',
    validators: [validatePasswordConfirm],
  },
];

const sendResetPasswordFields = [
  {
    name: 'email',
    validators: [validateEmail],
  },
];

export class AuthStore {
  loginForm = new MobxReactForm(
    { fields: loginFields },
    { plugins, options: { ...options, validateOnBlur: false, validateOnChangeAfterSubmit: true } },
  );

  registerForm = new MobxReactForm({ fields: registerFields }, { plugins, options });

  sendResetPasswordForm = new MobxReactForm({ fields: sendResetPasswordFields }, { plugins, options });

  resetPasswordForm = new MobxReactForm({ fields: registerFields }, { plugins, options });

  @observable user = null;

  @observable config = {};

  @observable registerError = '';

  @observable showRepeatVerify = false;

  constructor(store) {
    this.store = store;
    this.getUserInfo();
    this.getConfig();
  }

  getUserAuthorizationHeaders = data => ({
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${get(this.user, 'token')}`,
    },
    data: {
      data,
    },
  });

  hideModal = () => {
    this.store.authModalStore.hideModal();
  };

  @action.bound
  reset = () => {
    this.registerError = '';
    this.registerForm.reset();
    this.loginForm.reset();
    this.sendResetPasswordForm.reset();
  };

  @action.bound
  registerUser = () => {
    this.registerForm.submit({
      onSuccess: () => {
        const email = this.registerForm.$('email').value;
        const password = this.registerForm.$('password').value;

        const registerUser = {
          email,
          password,
        };

        axios
          .post(APIS.registerUser, registerUser)
          .then(res => res.data.user)
          .then(
            action('register user success', () => {
              this.user = null;
              this.reset();
              this.hideModal();
              openSuccessNotification(i18n.t('auth:registerSuccess'), i18n.t('auth:registerSuccessMessage', { email }));
            }),
          )
          .catch(
            action('register user fail', e => {
              this.user = null;
              const { data } = e.response;

              if (data.message && data.message === 'email_exist') {
                this.registerError = i18n.t('auth:emailExist');
                openErrorNotification(i18n.t('auth:emailExist'));
              } else {
                this.registerError = i18n.t('auth:registerFail');
                openErrorNotification(i18n.t('auth:registerFail'));
              }
            }),
          );
      },
      onError: action('register user fail', () => {}),
    });
  };

  @action.bound
  sendVerifyEmail = () => {
    this.showRepeatVerify = false;
    return axios
      .post(APIS.sendVerifyEmail, {
        email: this.loginForm.$('email').value,
      })
      .then(() => {
        openSuccessNotification(i18n.t('auth:sendVerifyEmailSuccessMessage'));
      })
      .catch(() => {
        openErrorNotification(i18n.t('auth:sendVerifyEmailFailedMessage'));
      });
  };

  @action.bound
  sendResetPasswordEmail = () => {
    this.sendResetPasswordForm.submit({
      onSuccess: () => {
        axios
          .post(APIS.sendResetPasswordEmail, {
            email: this.sendResetPasswordForm.$('email').value,
          })
          .then(() => {
            this.hideModal();
            openSuccessNotification(i18n.t('auth:sendResetPasswordEmailSuccessMessage'));
          })
          .catch(() => {
            openErrorNotification(i18n.t('auth:sendResetPasswordEmailFailedMessage'));
          });
      },
      onError: action('sendResetPasswordEmail user fail', () => {}),
    });
  };

  @action.bound
  resetPassword = token => {
    this.resetPasswordForm.submit({
      onSuccess: () => {
        axios
          .post(APIS.resetPassword, {
            token,
            email: this.resetPasswordForm.$('email').value,
            password: this.resetPasswordForm.$('password').value,
          })
          .then(res => res.data.user)
          .then(user => {
            this.user = user;
            this.reset();
            this.store.routerStore.push(Url.home);
            openSuccessNotification(i18n.t('auth:resetPasswordSuccessMessage'));
          })
          .catch(() => {
            openErrorNotification(i18n.t('auth:resetPasswordFailedMessage'));
          });
      },
      onError: action('resetPassword failed', () => {}),
    });
  };

  @action.bound
  loginUser = () => {
    this.loginForm.submit({
      onSuccess: () => {
        const loginUser = {
          email: this.loginForm.$('email').value,
          password: this.loginForm.$('password').value,
        };

        this.loginInternal(APIS.loginUser, loginUser).catch(
          action('login fail', e => {
            const { data } = e.response;
            this.user = null;

            this.loginForm.invalidate(i18n.t('auth:loginFailed'));

            if (data.message) {
              if (data.message === 'invalid_email_or_password') {
                this.loginForm.invalidate(i18n.t('auth:loginEmailOrPasswordIncorrect'));
              } else if (data.message === 'user_not_verified') {
                this.loginForm.invalidate(i18n.t('auth:userNotVerified'));
                this.showRepeatVerify = true;
              }
            }

            openErrorNotification(i18n.t('auth:loginFailed'));
          }),
        );
      },
      onError: action('register user fail', form => {
        form.invalidate(i18n.t('auth:loginEmailOrPasswordIncorrect'));
      }),
    });
  };

  @action.bound
  loginFacebook = response => {
    const token = { access_token: response.accessToken };

    this.loginInternal(APIS.loginFacebook, token).catch(
      action('login facebook fail', () => {
        this.user = null;
        this.loginForm.invalidate(i18n.t('auth:loginFailed'));
        openErrorNotification(i18n.t('auth:loginFailed'));
      }),
    );
  };

  @action.bound
  loginGoogle = response => {
    const token = { token: response.tokenId };
    this.loginInternal(APIS.loginGoogle, token).catch(
      action('login google fail', () => {
        this.user = null;
        this.loginForm.invalidate(i18n.t('auth:loginFailed'));
        openErrorNotification(i18n.t('auth:loginFailed'));
      }),
    );
  };

  @action.bound
  loginSocialFailed = () => {
    this.user = null;
    this.loginForm.invalidate(i18n.t('auth:loginFailed'));
    openErrorNotification(i18n.t('auth:loginFailed'));
  };

  @action.bound
  loginInternal = (url, data) => {
    return axios
      .post(url, data, { withCredentials: true })
      .then(res => res.data.user)
      .then(
        action('login facebook success', user => {
          if (['admin', 'mod'].some(r => user.roles.includes(r))) {
            this.user = user;
            this.reset();
            this.hideModal();
            openSuccessNotification(i18n.t('auth:loginSuccess'));
          } else {
            this.user = null;
            this.reset();
            this.hideModal();
            openErrorNotification(i18n.t('auth:loginFailed'));
          }
        }),
      );
  };

  @action.bound
  getConfig = () => {
    axios
      .get(APIS.config)
      .then(res => res.data)
      .then(
        action('get user success', config => {
          this.config = config;
        }),
      )
      .catch(action('get user fail', () => {}));
  };

  @action.bound
  getUserInfo = () => {
    axios
      .get(APIS.infoUser, { withCredentials: true })
      .then(res => res.data.user)
      .then(
        action('get user success', user => {
          this.user = user;
          this.reset();
          this.hideModal();
        }),
      )
      .catch(
        action('get user fail', () => {
          this.user = null;
        }),
      );
  };

  @action.bound
  logoutUser = () => {
    axios.get(APIS.logoutUser).then(
      action('logout user success', () => {
        openSuccessNotification(i18n.t('auth:logoutSuccess'));
        this.user = null;
        this.reset();
        this.hideModal();
      }),
    );
  };

  @action.bound
  verifyUser = token => {
    return axios.get(APIS.verifyUser(token)).then(res => {
      this.user = res.data.user;
    });
  };

  @computed
  get isLoggedIn() {
    return this.user;
  }

  @computed
  get roles() {
    return get(this.user, 'roles', []);
  }
}
