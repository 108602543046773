import styled from 'styled-components';
import { Dropdown, Icon } from 'antd';
import { lighten } from 'polished';
import { Colors, Breakpoints } from '../index';

export const LanguagesDropdownStyled = styled(Dropdown).attrs(() => ({
  placement: 'bottomCenter',
}))`
  font-size: 14px;
  padding: 5px 10px;
  color: ${Colors.black};
  font-weight: 600;

  &.selected {
    color: ${Colors.primaryColor};
  }

  :hover {
    color: ${Colors.black};
    border-bottom-color: ${lighten(0.2, Colors.primaryColor)};
  }

  ${Breakpoints.medium} {
    border-bottom: 3px solid transparent;
  }
`;
export const DownOutLined = styled(Icon).attrs(() => ({
  type: 'down',
}))`
  margin-left: 5px;
  font-weight: 600;
`;
