import axios from 'axios';
import { CONFIGS } from '../shared';

const requestHandler = request => {
  if (['post', 'put', 'delete'].includes(request.method)) {
    // Dispatch goes here
  } else if (request.method === 'get') {
    // Dispatch goes here
  }

  return request;
};

const successHandler = response => {
  // Dispatch goes here
  return response.data;
};

const errorHandler = error => {
  return Promise.reject(error.response ? error.response.data : error);
};

const DATA_API = axios.create({
  baseURL: `${CONFIGS.DATA_API_PREFIX}/`,
  timeout: 30000,
});

DATA_API.interceptors.request.use(request => requestHandler(request));

DATA_API.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error),
);

export default DATA_API;
