import { action, observable } from 'mobx';

export class UserModalStore {
  @observable visible = false;

  constructor(store) {
    this.store = store;
  }

  @action.bound
  showModal = () => {
    this.visible = true;
  };

  @action.bound
  hideModal = () => {
    this.visible = false;
  };
}
