import React from 'react';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { LoginFormContainer } from './LoginForm';
import { RegisterFormContainer } from './RegisterForm';
import { ResetPasswordFormContainer } from './SendResetPasswordForm';
import { Modal } from '../index';
import { ModalTypeEnum } from './authModalStore';

const AuthComponent = ({ modalType }) => {
  if (modalType === ModalTypeEnum.login) {
    return <LoginFormContainer />;
  }

  if (modalType === ModalTypeEnum.register) {
    return <RegisterFormContainer />;
  }

  if (modalType === ModalTypeEnum.resetPassword) {
    return <ResetPasswordFormContainer />;
  }

  return null;
};

const AuthModal = ({ modalType, hideModal }) => (
  <Modal isOpen={!isEmpty(modalType)} onRequestClose={hideModal}>
    <AuthComponent modalType={modalType} />
  </Modal>
);

export const AuthModalContainer = inject(({ store: { authModalStore } }) => {
  return {
    hideModal: authModalStore.hideModal,
    modalType: authModalStore.modalType,
  };
})(observer(AuthModal));
