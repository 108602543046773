import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { Button } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Url } from '../../index';
import { BoardForm, H4 } from '../index';

const ProtectedComponentStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
`;

const ButtonStyled = styled(Button)`
  margin-right: 20px;
  margin-bottom: 20px;
`;

const ProtectedComponent = ({ isLoggedIn, children, message, showLogin, showRegister, t }) => {
  if (isLoggedIn) {
    return children;
  }
  return (
    <BoardForm>
      <ProtectedComponentStyled>
        <H4>{message}</H4>
        <div>
          <ButtonStyled onClick={showLogin} type="primary">
            {t('loginText')}
          </ButtonStyled>
          <ButtonStyled onClick={showRegister} type="primary">
            {t('register')}
          </ButtonStyled>
        </div>
        <NavLink to={Url.home}>{t('returnHome')}</NavLink>
      </ProtectedComponentStyled>
    </BoardForm>
  );
};

export const ProtectedContainer = inject(({ store: { authStore, authModalStore } }) => {
  const [t] = useTranslation(['auth'], { useSuspense: false });
  return {
    isLoggedIn: authStore.isLoggedIn && ['admin', 'mod'].some(r => authStore.roles.includes(r)),
    showLogin: authModalStore.showLogin,
    showRegister: authModalStore.showRegister,
    t,
  };
})(observer(ProtectedComponent));
