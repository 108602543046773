import styled from 'styled-components';
import { Colors } from '../styles/Color.styles';
import { Breakpoints } from '../index';

export const WrapperStyled = styled.div`
  background: ${Colors.white}
  margin-top: 10px;
  cursor: pointer;
  .slick-arrow {
    opacity: 1;
  }
  .slick-prev {
    left: 5px;
    z-index: 9999;
    &:before {
      color: ${Colors.primaryColor};
    }
  }
  .slick-next {
    right: 5px;
    z-index: 9999;
    &:before {
      color: ${Colors.primaryColor};
    }
  }

  ${Breakpoints.medium} {
   .slick-arrow {
      opacity: 0;
    }
    .slick-prev {
       &:before {
          font-size: 25px;
       }
    }
    .slick-next {
      &:before {
        font-size: 25px;
      }
    }
    &:hover .slick-arrow {
      opacity: 1;
      }
  }
`;
