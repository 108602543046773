import React from 'react';
import { inject, observer } from 'mobx-react';
import { Icon, Input, Button, Divider } from 'antd';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import { useTranslation } from 'react-i18next';
import GoogleIcon from './GoogleIcon';
import { A } from '../index';
import { FormStyled, FormItemStyled, ErrorStyled, FaceBookBtn, GoogleBtn } from './Auth.styles';

const LoginForm = ({
  loginGoogle,
  loginSocialFailed,
  sendVerifyEmail,
  showRepeatVerify,
  config,
  showRegister,
  showResetPassword,
  loginUser,
  loginFacebook,
  loginForm,
}) => {
  const [t] = useTranslation(['auth'], { useSuspense: false });
  return (
    <FormStyled>
      <FacebookLogin
        appId={config.facebookAppId}
        autoLoad={false}
        fields="name,email,picture"
        callback={loginFacebook}
        textButton={t('loginFacebook')}
        render={renderProps => (
          <FaceBookBtn onClick={renderProps.onClick}>
            <Icon type="facebook" /> {t('loginFacebook')}
          </FaceBookBtn>
        )}
      />
      <GoogleLogin
        clientId={config.googleAppId}
        onSuccess={loginGoogle}
        onFailure={loginSocialFailed}
        cookiePolicy="single_host_origin"
        render={renderProps => (
          <GoogleBtn onClick={renderProps.onClick} disabled={renderProps.disabled}>
            <GoogleIcon /> <div>{t('loginGoogle')}</div>
          </GoogleBtn>
        )}
      />
      <Divider>{t('or')}</Divider>
      <FormItemStyled>
        <Input {...loginForm.$('email').bind()} size="large" prefix={<Icon type="user" />} placeholder={t('email')} />
      </FormItemStyled>
      <FormItemStyled>
        <Input.Password
          size="large"
          prefix={<Icon type="lock" />}
          type="password"
          {...loginForm.$('password').bind()}
          placeholder={t('password')}
        />
      </FormItemStyled>
      {loginForm.error && <ErrorStyled>{loginForm.error}</ErrorStyled>}
      {showRepeatVerify && (
        <A onClick={sendVerifyEmail}>
          {t('clickHereToResendVerificationEmail', { email: loginForm.$('email').value })}
        </A>
      )}
      <FormItemStyled>
        <Button type="primary" onClick={loginUser} size="large" block>
          {t('loginText')}
        </Button>
      </FormItemStyled>
      <FormItemStyled>
        {t('noAccount')} <A onClick={showRegister}>{t('registerNow')}</A>
        <br />
        {t('forgetPassword')} <A onClick={showResetPassword}>{t('resetPassword')}</A>
      </FormItemStyled>
    </FormStyled>
  );
};

export const LoginFormContainer = inject(({ store: { authStore, authModalStore } }) => ({
  showRegister: authModalStore.showRegister,
  showResetPassword: authModalStore.showResetPassword,
  loginUser: authStore.loginUser,
  loginFacebook: authStore.loginFacebook,
  loginGoogle: authStore.loginGoogle,
  loginSocialFailed: authStore.loginSocialFailed,
  loginForm: authStore.loginForm,
  config: authStore.config,
  showRepeatVerify: authStore.showRepeatVerify,
  sendVerifyEmail: authStore.sendVerifyEmail,
}))(observer(LoginForm));
