import styled from 'styled-components';
import { Colors, Container } from '../../../index';

export const FooterStyled = styled.div`
  border-top: 1px solid ${Colors.gray1};
  background-color: ${Colors.white};
  font-weight: 500;
`;

export const FooterInnerStyled = styled(Container)`
  padding-top: 20px;
`;

export const LogoStyled = styled.div`
  height: 40px;
`;

export const RightLabelStyled = styled.div`
  color: ${Colors.black};
  font-weight: 600;
  justify-content: flex-end;
  display: flex;
`;

export const RightBlockStyled = styled.div`
  padding: 10px 0;
  display: flex;
  align-items: center;
`;

export const MenusStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MenuStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  flex-grow: 3;
`;
