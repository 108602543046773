import { action, observable } from 'mobx';

export const ModalTypeEnum = {
  login: 'login',
  register: 'register',
  resetPassword: 'resetPassword',
};

export class AuthModalStore {
  @observable modalType = null;

  constructor(store) {
    this.store = store;
  }

  reset = () => {
    this.store.authStore.reset();
  };

  @action.bound
  showLogin = () => {
    this.reset();
    this.modalType = ModalTypeEnum.login;
  };

  @action.bound
  showRegister = () => {
    this.reset();
    this.modalType = ModalTypeEnum.register;
  };

  @action.bound
  showResetPassword = () => {
    this.reset();
    this.modalType = ModalTypeEnum.resetPassword;
  };

  @action.bound
  hideModal = () => {
    this.reset();
    this.modalType = null;
  };
}
