import React from 'react';
import { compose } from 'recompose';
import { Button, Icon, Input } from 'antd';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { A } from '../index';
import { FormItem, FormItemStyled, FormStyled, ErrorStyled } from './Auth.styles';

const RegisterForm = observer(props => {
  const { showLogin, registerUser, registerForm, registerError } = props;
  const [t] = useTranslation(['auth'], { useSuspense: false });
  return (
    <FormStyled>
      <FormItem data={registerForm.$('email')}>
        <Input
          {...registerForm.$('email').bind()}
          size="large"
          prefix={<Icon type="user" />}
          placeholder={t('email')}
        />
      </FormItem>
      <FormItem data={registerForm.$('password')}>
        <Input.Password
          {...registerForm.$('password').bind()}
          size="large"
          prefix={<Icon type="lock" />}
          type="password"
          placeholder={t('password')}
        />
      </FormItem>
      <FormItem data={registerForm.$('passwordConfirm')}>
        <Input.Password
          {...registerForm.$('passwordConfirm').bind()}
          size="large"
          prefix={<Icon type="lock" />}
          type="password"
          placeholder={t('passwordConfirm')}
        />
      </FormItem>
      {registerError && <ErrorStyled>{registerError}</ErrorStyled>}
      <FormItemStyled>
        <Button type="primary" onClick={registerUser} size="large" block>
          {t('register')}
        </Button>
      </FormItemStyled>
      <FormItemStyled>
        {t('hasAccount')} <A onClick={showLogin}>{t('loginText')}</A>
      </FormItemStyled>
    </FormStyled>
  );
});

export const RegisterFormContainer = compose(
  inject(({ store: { authStore, authModalStore } }) => ({
    showLogin: authModalStore.showLogin,
    registerUser: authStore.registerUser,
    registerForm: authStore.registerForm,
    registerError: authStore.registerError,
  })),
)(RegisterForm);
