import axios from 'axios';
import { observable, action, reaction } from 'mobx';
import { debounce, isNil } from 'lodash';

export class AutoCompleteStore {
  getUrl;

  @observable.shallow items = [];

  @observable value = '';

  @observable typesCategory = '';

  constructor(getUrl, normalizer, store, types) {
    this.getUrl = getUrl;
    this.normalizer = normalizer;
    this.store = store || {};
    this.fetch();
    this.typesCategory = types || this.typesCategory;
  }

  reaction() {
    reaction(
      () => this.store.languagesStore.locale,
      locale => {
        if (locale) {
          this.fetch();
        }
      },
    );
  }

  @action.bound
  reset = () => {
    this.value = '';
    this.fetch();
  };

  @action.bound
  onChange = value => {
    this.value = isNil(value) ? '' : value;
    this.fetch();
  };

  @action.bound
  setItems = items => {
    this.items = items;
  };

  @action.bound
  setValue = value => {
    this.value = value;
  };

  @action.bound
  fetch = debounce(() => {
    const lang = this.store?.languagesStore?.locale;
    axios
      .get(this.getUrl(this.value, lang, this.typesCategory))
      .then(res => res.data)
      .then(
        action('fetch auto-suggestions success', data => {
          this.items = this.normalizer(data);
        }),
      );
  }, 500);
}
