import React from 'react';
import { Col, Row } from 'antd';
import { MainLayout } from '../../shared/components/shared';
import { UserPerDay } from './charts/UserPerDay';
import { BrowserByUser } from './charts/BrowserByUser';
import { PageUserVisit } from './charts/PageUserVisit';
import { DiviceByUser } from './charts/DiviceByUser';
import { AggregationMonthCrawled, AggregationMonthProcessed } from './charts/AggregationCrawled';

export const Home = () => {
  return (
    <MainLayout>
      {/*<Row>*/}
      {/*  <AggregationMonthCrawled />*/}
      {/*  <AggregationMonthProcessed />*/}
      {/*</Row>*/}
      <Row>
        <Col span={12}>
          <UserPerDay />
        </Col>
        <Col span={12}>
          <BrowserByUser />
        </Col>
        <Col span={12}>
          <PageUserVisit />
        </Col>
        <Col span={12}>
          <DiviceByUser />
        </Col>
      </Row>
    </MainLayout>
  );
};
