import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Form, Icon, Input, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { Modal } from '../modal/Modal';

const AddImageLinkFormStyled = styled.div`
  padding: 20px;
`;

const AddImageLinkInputStyled = styled.span`
  margin-right: 20px;
`;

const AddImageLinkInput = ({ onLinkAdded }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [link, setLink] = useState('');
  const [t] = useTranslation(['common']);
  function toggleModal() {
    setIsOpen(!isOpen);
  }

  function onLinkChange(e) {
    setLink(e.target.value);
  }

  function addLink() {
    toggleModal();
    onLinkAdded(link);
  }

  return (
    <AddImageLinkInputStyled>
      <Button onClick={toggleModal}>
        <Icon type="link" /> {t('input.addImageLink')}
      </Button>
      <Modal isOpen={isOpen} onRequestClose={toggleModal}>
        <AddImageLinkFormStyled>
          <Form layout="vertical">
            <Form.Item label={t('input.imageLink')}>
              <Input onChange={onLinkChange} />
            </Form.Item>
          </Form>
          <Button onClick={toggleModal}>{t('input.cancel')}</Button>
          &nbsp;&nbsp;
          <Button type="primary" onClick={addLink}>
            {t('input.addImageLink')}
          </Button>
        </AddImageLinkFormStyled>
      </Modal>
    </AddImageLinkInputStyled>
  );
};

export const InputUpload = ({ onLinkAdded, fileList, onFileListChange, action }) => {
  const [t] = useTranslation(['common']);
  return (
    <>
      <AddImageLinkInput onLinkAdded={onLinkAdded} />
      <Upload fileList={fileList} onChange={onFileListChange} name="image" action={action} listType="picture">
        <Button>
          <Icon type="upload" /> {t('input.uploadImage')}
        </Button>
      </Upload>
    </>
  );
};
export const InputUploadMultiple = ({ onLinkAdded, fileList, onFileListChange, action }) => {
  const [t] = useTranslation(['common']);
  return (
    <>
      <AddImageLinkInput onLinkAdded={onLinkAdded} />
      <Upload
        fileList={fileList}
        onChange={onFileListChange}
        name="image"
        action={action}
        listType="picture-card"
        multiple
      >
        <Button>
          <Icon type="upload" /> {t('input.uploadImage')}
        </Button>
      </Upload>
    </>
  );
};
