export const CONFIGS = {
  googleMapKey: process.env.REACT_APP_GOOLE_MAP_KEY || 'AIzaSyBxAUdS0gkAlDiE6eTDQvWNdAJ-Z-Nm_z4',
  HOST_NAME: process.env.REACT_APP_VIETSEARCH_HOST || 'https://test.vietsearch.org',
  API_PREFIX: process.env.REACT_APP_API_PREFIX || 'https://api.test.vietsearch.org/api',
  USER_PREFIX: process.env.REACT_APP_USER_API_PREFIX || 'https://api.test.vietsearch.org/users-api',
  DATA_API_PREFIX: process.env.REACT_APP_DATA_API_PREFIX || 'https://api.test.vietsearch.org/data-api',
  CRAWL_API_PREFIX: process.env.REACT_APP_CRAWL_API_PREFIX || 'https://api.test.vietsearch.org/crawler-api',
  STORAGE_API_PREFIX: process.env.REACT_APP_STORAGE_API_PREFIX || 'https://api.test.vietsearch.org/storage-api',
  NODE_API_PREFIX: process.env.REACT_APP_NODE_API_PREFIX || 'https://api.test.vietsearch.org/node-api',
  GTM_TRACKING_ID: process.env.REACT_APP_GTM_TRACKING_ID || 'GTM-MCSQT2H',
  CRAWLER_API_KEY: process.env.VIETSEARCH_CRAWLER_API_KEY || '4389479824237842#@@#$2',
  CRAWLER_API_KEY_EMAIL: process.env.VIETSEARCH_CRAWLER_API_KEY_EMAIL || 'vietsearch.user@gmail.com',
};
