import { notification } from 'antd';

const openNotification = (type, message, description) => {
  notification[type]({
    message,
    description,
  });
};

export const openSuccessNotification = (message, description) => {
  openNotification('success', message, description);
};

export const openInfoNotification = (message, description) => {
  openNotification('info', message, description);
};

export const openErrorNotification = (message, description) => {
  openNotification('error', message, description);
};
export const openWarningNotification = (message, description) => {
  openNotification('warning', message, description);
};
