import axios from 'axios';
import { action, observable, computed } from 'mobx';
import { isEmpty } from 'lodash';
import { UsersApis } from './api';
import { logger } from '../../shared';
import { getStart } from '../../shared/helper';
import { EntryUrls } from '../entries/url';
import { EntryApis } from '../entries/api';
import { EventUrls } from '../events/url';

export const myEntriesTypeEnum = {
  business: {
    value: 'business',
    key: 'business',
  },
  experts: {
    value: 'experts',
    key: 'person',
  },
  events: {
    value: 'events',
    key: 'event',
  },
};

export class MyEntriesStore {
  @observable myEntries = null;

  @observable typeEntries = myEntriesTypeEnum.business.value;

  @observable.shallow pagination = {
    pageSize: 10,
    showSizeChanger: true,
    current: 1,
  };

  @observable pageSize = 1;

  @observable isLoading = true;

  constructor(store) {
    this.store = store;
  }

  @computed get showEmpty() {
    return isEmpty(this.myEntries) && !this.isLoading;
  }

  @observable setTypeEntries = type => {
    this.typeEntries = type;
  };

  @observable onChangeTypeEntries = type => {
    this.setTypeEntries(type);
    this.fetchEntries();
  };

  @action.bound
  fetchEntries = (resetPage = true) => {
    if (resetPage) {
      this.page = 1;
    }

    this.isLoading = true;

    if (this.typeEntries === myEntriesTypeEnum.events.value) {
      axios
        .get(
          UsersApis.events(this.pagination.pageSize, getStart(this.pagination.pageSize, this.pagination.current)),
          this.store.authStore.getUserAuthorizationHeaders(),
        )
        .then(res => res.data)
        .then(
          action('fetch items my entries events success', ({ total, events }) => {
            this.pagination = { ...this.pagination, total: total.value };
            this.myEntries = events;
          }),
        )
        .then(() => {
          this.isLoading = false;
        })
        .catch(
          action('get my entries events items fail', error => {
            logger.log(error);
          }),
        );
    } else {
      axios
        .get(
          UsersApis.entries(
            this.pagination.pageSize,
            getStart(this.pagination.pageSize, this.pagination.current),
            myEntriesTypeEnum?.[this.typeEntries]?.key ?? myEntriesTypeEnum.business.value,
          ),
          this.store.authStore.getUserAuthorizationHeaders(),
        )
        .then(res => res.data)
        .then(
          action('fetch items my entries success', ({ total, entries }) => {
            this.pagination = { ...this.pagination, total: total.value };
            this.myEntries = entries;
          }),
        )
        .then(() => {
          this.isLoading = false;
        })
        .catch(
          action('get my entries items fail', error => {
            logger.log(error);
          }),
        );
    }
  };

  @action.bound
  onPageChanged = ({ current, pageSize }) => {
    this.pagination.current = current;
    this.pagination.pageSize = pageSize;
    this.store.windowStore.scrollToTop();
    this.fetchEntries(false);
  };

  onSearchUpdateById = id => {
    axios
      .get(EntryApis.entryDetail(id))
      .then(res => res.data)
      .then(
        action('fetch detail entry success', data => {
          const { entry } = data;
          if (entry.types[0] === myEntriesTypeEnum.business.key) {
            this.store.routerStore.push({ pathname: EntryUrls.getUpdateBusinessPath(id) });
          } else if (entry.types[0] === myEntriesTypeEnum.expert.key) {
            this.store.routerStore.push({ pathname: EntryUrls.getUpdatePath(id) });
          } else if (entry.types[0] === myEntriesTypeEnum.events.key) {
            this.store.routerStore.push({ pathname: EventUrls.getUpdatePath(id) });
          }
        }),
      )
      .catch(
        action('get items fail', e => {
          console.log(e);
        }),
      );
  };
}
