import React from 'react';
import { compose } from 'recompose';
import { inject } from 'mobx-react';
import { Translation, useTranslation } from 'react-i18next';
import { Container, Carousel, Board } from '../../index';
import { TopBusinessCardStyled, TopBusinessImgStyled, TitleStyled, WrapperStyled } from './TopBusiness.styles';
import { findTypeEnum } from '../../../../store/vietsearchStore';

class TopBusinessCarousel extends React.Component {
  renderItem = item => {
    const { onClick } = this.props;

    return (
      <TopBusinessCardStyled key={item.id} onClick={() => onClick(item)}>
        <TopBusinessImgStyled src={item.thumbnail} alt="" />
        <TitleStyled>
          <Translation>{(t, { i18n }) => item.names[i18n.language]}</Translation>
        </TitleStyled>
      </TopBusinessCardStyled>
    );
  };

  render() {
    const { businessCategories } = this.props;
    return <Carousel items={businessCategories} renderItem={this.renderItem} />;
  }
}

const TopBusinessCarouselContainer = compose(
  inject(({ store: { homepageStore, vietsearchStore, languagesStore } }) => {
    return {
      businessCategories: homepageStore.businessCategories,
      onClick: item => {
        vietsearchStore.topCategory(item.id, item.names[languagesStore.locale], findTypeEnum.business);
      },
    };
  }),
)(TopBusinessCarousel);

export const TopBusiness = () => {
  const [t] = useTranslation(['home']);
  return (
    <WrapperStyled>
      <Container>
        <Board title={t('recommendation.topBusiness')}>
          <TopBusinessCarouselContainer />
        </Board>
      </Container>
    </WrapperStyled>
  );
};
