import React from 'react';
import { Provider } from 'mobx-react';
import { ModalProvider } from 'styled-react-modal';
import { Routes } from './routes/Routes';
import { store } from './store';
import { AppStyled } from './App.styles';

const history = store.routerStore.createHistory();

export const App = () => {
  return (
    <ModalProvider>
      <AppStyled />
      <Provider store={store}>
        <Routes history={history} />
      </Provider>
    </ModalProvider>
  );
};
