import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BoardForm } from '../index';
import text from './authMessage.json';

const ButtonStyled = styled(Button)`
  margin-right: 20px;
  margin-bottom: 20px;
`;

const { confirm } = Modal;

function showDeleteConfirm(deleteAction) {
  confirm({
    title: text.deleteTitle,
    okText: text.ok,
    okType: 'danger',
    cancelText: text.cancel,
    onOk() {
      deleteAction();
    },
    onCancel() {},
  });
}

const ModComponent = ({ isMod, updateAction, deleteAction }) => {
  const [t] = useTranslation(['entry'], { useSuspense: false });

  if (!isMod) {
    return null;
  }

  return (
    <BoardForm>
      <ButtonStyled type="primary" onClick={updateAction}>
        {t('entry:update')}
      </ButtonStyled>
      <ButtonStyled onClick={() => showDeleteConfirm(deleteAction)} type="danger">
        {t('entry:delete.root')}
      </ButtonStyled>
    </BoardForm>
  );
};

export const ModActionsContainer = inject(
  ({ store: { authStore, routerStore } }, { updateUrl, deleteAction, showVerify, onChangeStatus, status }) => {
    return {
      isMod: ['admin', 'mod'].some(r => authStore.roles.includes(r)),
      updateAction: () => routerStore.push(updateUrl),
      deleteAction,
      onChangeStatus,
      status,
    };
  },
)(observer(ModComponent));
