import axios from 'axios';
import { isEmpty, isNull, get } from 'lodash';
import { observable, computed, action } from 'mobx';
import { APIS } from './apis';
import { findTypeEnum } from './vietsearchStore';

export class WhatStore {
  routerStore;

  @observable.shallow items = null;

  @observable activeItem = null;

  @observable zoom = undefined;

  @observable.shallow center = undefined;

  @observable.shallow bounds = undefined;

  @observable total = 0;

  @observable page = 1;

  @observable pageSize = 30;

  @observable isLoading = false;

  constructor(store) {
    this.store = store;
  }

  @computed get activeItemId() {
    return this.activeItem && this.activeItem.id;
  }

  @computed get showEmpty() {
    return isNull(this.items) || this.searchedEmpty;
  }

  @computed get searchedEmpty() {
    return this.items && isEmpty(this.items);
  }

  @computed get pagination() {
    return {
      pageSize: this.pageSize,
      showSizeChanger: true,
      current: this.page,
      total: this.total,
    };
  }

  @action.bound
  reset = () => {
    this.total = 0;
    this.page = 1;
    this.items = null;
    this.activeItem = null;
  };

  @action.bound
  onCenterChange = center => {
    this.center = center;
  };

  @action.bound
  onZoomChange = zoom => {
    this.zoom = zoom;
  };

  @action.bound
  onBoundsChange = bounds => {
    this.bounds = bounds;
  };

  @action.bound
  onPageChanged = ({ current, pageSize }) => {
    this.page = current;
    this.pageSize = pageSize;
    this.store.windowStore.scrollToTop();

    this.fetchWhat(false);
  };

  onMapLoaded = ({ map, maps }) => {
    this.map = map;
    this.maps = maps;

    this.refreshBounds();
  };

  refreshBounds = () => {
    if (!this.maps) return;

    const bounds = new this.maps.LatLngBounds();

    this.items.forEach(item => {
      bounds.extend(new this.maps.LatLng(item.lat, item.lng));
    });

    this.map.fitBounds(bounds);
  };

  @action.bound
  setActiveItem = activeItem => {
    this.activeItem = activeItem;
    if (activeItem) {
      this.onCenterChange({ lat: activeItem.lat, lng: activeItem.lng });
      this.onZoomChange(10);
    }
  };

  @action.bound
  fetchWhat = (resetPage = true) => {
    if (resetPage) {
      this.page = 1;
    }
    this.isLoading = true;
    const what = this.store.whatInputStore.value;
    const where = this.store.placeInputStore.value;
    const { category, types, countryCode } = this.store.vietsearchStore;
    const findTypes = types === findTypeEnum.all ? '' : types;
    const lang = this.store.languagesStore.locale;
    const { status } = this.store.vietsearchStore;
    const { sort } = this.store.vietsearchStore;
    const { order } = this.store.vietsearchStore;
    axios
      .get(
        APIS.entrySearch(
          what,
          where,
          findTypes,
          '',
          category,
          this.pageSize,
          this.getStart(),
          lang,
          undefined,
          countryCode,
          status,
          sort,
          order,
        ),
      )
      .then(res => res.data)
      .then(
        action('fetch items success', ({ total, entries }) => {
          this.isLoading = false;
          this.total = total.value;
          this.items = entries.map(resItem => ({
            ...resItem,
            id: resItem.id,
            name: resItem.name,
            title: resItem.title,
            address: resItem.address.displayed,
            type: resItem.types ? resItem.types[0] : null,
            thumbnail: resItem.thumbnail,
            web: resItem.web,
            lng: get(resItem, 'location.lon', 0),
            lat: get(resItem, 'location.lat', 0),
          }));

          this.refreshBounds();

          this.store.windowStore.scrollToEl(this.searchBoxEl);
          this.store.windowStore.scrollElToTop(this.searchResultsEl);
        }),
      );
  };

  @action.bound
  fetchListEntries = (resetPage = true) => {
    if (resetPage) {
      this.page = 1;
    }
    this.isLoading = true;
    const what = this.store.whatInputStore.value;
    const where = this.store.entryPageStore.whereInput || this.store.placeInputStore.value;
    const { types, catIds } = this.store.entryPageStore;
    const { countryCode } = this.store.vietsearchStore;

    axios
      .get(APIS.entrySearch(what, where, types, '', catIds, this.pageSize, this.getStart(), null, null, countryCode))
      .then(res => res.data)
      .then(
        action('fetch items success', ({ total, entries }) => {
          this.isLoading = false;
          this.total = total.value;
          this.items = entries.map(resItem => ({
            ...resItem,
            id: resItem.id,
            name: resItem.name,
            title: resItem.title,
            address: resItem.address.displayed,
            type: resItem.types[0],
            thumbnail: resItem.thumbnail,
            web: resItem.web,
            lng: get(resItem, 'location.lon', 0),
            lat: get(resItem, 'location.lat', 0),
          }));

          this.refreshBounds();

          this.store.windowStore.scrollToEl(this.searchBoxEl);
          this.store.windowStore.scrollElToTop(this.searchResultsEl);
        }),
      );
  };

  getStart = () => {
    return this.pageSize * (this.page - 1);
  };

  setSearchBoxEl = el => {
    this.searchBoxEl = el;
  };

  setSearchResultsEl = el => {
    this.searchResultsEl = el;
  };
}
