import styled, { keyframes } from 'styled-components';

const SlideInLeftKeyFrames = keyframes`
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;

const SlideInRightKeyFrames = keyframes`
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;
export const SlideIn = styled.div`
  animation:
  ${props => {
    switch (props.type) {
      case 'left':
        return SlideInLeftKeyFrames;
      case 'right':
        return SlideInRightKeyFrames;
      default:
        return SlideInLeftKeyFrames;
    }
  }}
 animation-duration: ${props => (props.duration ? `${props.duration}s` : '0.5s')};
 animation-timing-function: ${props => (props.timingFunction ? props.timingFunction : 'linear')};
 animation-delay:   ${props => (props.delay ? `${props.delay}s` : '0s')};
`;
