import { action, computed, observable } from 'mobx';
import axios from 'axios';
import { isEmpty, values } from 'lodash';
import { logger } from '../../../shared';
import { CategoryApis } from '../api';
import { getStart } from '../../../shared/helper';
import { categoryTypesEnum } from '../../../shared/enum';

export class CategoryPageStore {
  categoryTypes = values(categoryTypesEnum);

  @observable categories = [];

  @observable totalCategory = 0;

  @observable currentPage = 1;

  @observable pageSize = 15;

  @observable searchValue = '';

  @observable category = undefined;

  @observable isLoading = true;

  @observable typesFilter = undefined;

  constructor(store) {
    this.store = store;
  }

  @computed get pagination() {
    return {
      pageSize: this.pageSize,
      showSizeChanger: true,
      current: this.currentPage,
      total: this.totalCategory,
    };
  }

  @action.bound
  setTypesFilter = value => {
    if (value) {
      this.typesFilter = value.toString();
    } else {
      this.typesFilter = value;
    }
    this.fetchCategories(true);
  };

  @action.bound
  onPageChanged = ({ current, pageSize }) => {
    this.currentPage = current;
    this.pageSize = pageSize;
    this.store.windowStore.scrollToTop();

    this.fetchCategories(false);
  };

  @action.bound
  fetchCategories = (resetPage = false) => {
    if (resetPage) {
      this.currentPage = 1;
    }
    const searchValue = !isEmpty(this.searchValue) ? this.searchValue : undefined;

    axios
      .get(
        CategoryApis.search(getStart(this.pageSize, this.currentPage), this.pageSize, searchValue, this.typesFilter),
        this.store.authStore.getUserAuthorizationHeaders(),
      )
      .then(res => res.data)
      .then(
        action('fetch list user success', ({ categories, total }) => {
          this.categories = categories;
          this.totalCategory = total;
        }),
      )
      .catch(
        action('get items fail', error => {
          logger.log(error);
        }),
      );
  };

  @action.bound
  onSearch = value => {
    this.searchValue = value;
    this.fetchCategories(true);
  };
}
