import { stringify } from 'query-string';
import { CONFIGS } from '../../shared/config';

const VIETSEARCH_URL = CONFIGS.API_PREFIX;

export const EntryApis = {
  eventSearch: (what, where, after, before, catIds, countryCode, sort, lang, size, start) =>
    `${VIETSEARCH_URL}/events/search?${stringify({
      what,
      where,
      after,
      before,
      cat_ids: catIds,
      sort,
      lang,
      size,
      start,
      country_code: countryCode,
    })}`,

  entryCreate: `${CONFIGS.DATA_API_PREFIX}/entries`,
  entryCreateOne: `${CONFIGS.DATA_API_PREFIX}/entry`,
  entryUpdate: id => `${CONFIGS.DATA_API_PREFIX}/entries/${id}`,
  entryDelete: id => `${CONFIGS.DATA_API_PREFIX}/entries/${id}`,
  entryDetail: id => `${CONFIGS.API_PREFIX}/entries/${id}`,
  entryVerify: id => `${CONFIGS.DATA_API_PREFIX}/entries-status/${id}`,
  entryUploadImage: `${CONFIGS.STORAGE_API_PREFIX}/images`,
  extractResearchGate: url => `${CONFIGS.CRAWL_API_PREFIX}/extract/research_gate?url=${url}`,
  extractGoogelScholar: url => `${CONFIGS.CRAWL_API_PREFIX}/extract/google_scholar?url=${url}`,
  entryCategoryTabs: (what, where, types, catIds, categorySize, countrycodeSize) =>
    // eslint-disable-next-line max-len
    `${VIETSEARCH_URL}/entries/search?what=${what}&where=${where}&types=${types}&cat_ids=${catIds}&facets=category:${categorySize},country_code:${countrycodeSize},types:5&size=0`,
  entryExpertSimilar: (entry_id, size, lang) =>
    `${VIETSEARCH_URL}/entries/experts/similar?${stringify({
      size,
      lang,
      entry_id,
    })}`,
};
