import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import { Spin } from 'antd';

const LoadingStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Loading = () => (
  <LoadingStyled>
    <Spin size="large" />
  </LoadingStyled>
);

const lazyFallbackComponent = LazyComponent => ({ ...props }) => (
  <Suspense fallback={<Loading />}>
    <LazyComponent {...props} />
  </Suspense>
);

export const LazyRoute = ({ LazyComponent, ...props }) => (
  <Route {...props} component={lazyFallbackComponent(LazyComponent)} />
);
