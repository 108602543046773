import React from 'react';
import { Icon } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import ReactModal from 'react-modal';
import { Colors } from '../styles/Color.styles';

const ModalClassname = 'global-modal';
const OverlayClassname = 'global-overlay';

export const ModalStyled = createGlobalStyle`
  .${ModalClassname} {    
    background-color: ${Colors.white};
    border-radius: 10px;
    position: relative;
  }
  .${OverlayClassname} {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.65);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const CloseIcon = styled(Icon).attrs(() => ({
  type: 'close-circle',
  theme: 'filled',
}))`
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  font-size: 22px;
`;

export const Modal = ({ children, ...props }) => {
  const { onRequestClose } = props;
  return (
    <ReactModal
      ariaHideApp={false}
      shouldCloseOnOverlayClick
      className={ModalClassname}
      overlayClassName={OverlayClassname}
      {...props}
    >
      <ModalStyled />
      <CloseIcon onClick={onRequestClose} />
      {children}
    </ReactModal>
  );
};
