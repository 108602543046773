import { stringify } from 'query-string';
import { CONFIGS } from '../../shared/config';

const ROOT_API = CONFIGS.NODE_API_PREFIX;

export const UserApi = {
  users: (start = 0, size = 10, q) =>
    `${ROOT_API}/user?${stringify({
      start,
      size,
      q,
    })}`,
  detail: id => `${ROOT_API}/user/${id}`,
  update: id => `${ROOT_API}/user/${id}`,
};
