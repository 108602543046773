import React from 'react';
import { Chart } from 'react-google-charts';

export const BrowserByUser = () => {
  return (
    <Chart
      width="100%"
      height="400px"
      chartType="PieChart"
      loader={<div>Loading Chart</div>}
      data={[
        ['Browser', 'Total user in used browser'],
        ['Chrome', 1000],
        ['FireFox', 500],
        ['Coc coc', 300],
        ['Eagde', 100],
        ['Orther', 7],
      ]}
      options={{
        title: 'Browser by user',
      }}
      rootProps={{ 'data-testid': '1' }}
    />
  );
};
