import React from 'react';
import { compose } from 'recompose';
import { Button, Icon, Input } from 'antd';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { FormItem, FormItemStyled, FormStyled } from './Auth.styles';

const SendResetPasswordForm = observer(props => {
  const [t] = useTranslation(['auth'], { useSuspense: false });
  const { sendResetPasswordForm, sendResetPasswordEmail } = props;

  return (
    <FormStyled>
      <FormItem data={sendResetPasswordForm.$('email')}>
        <Input
          {...sendResetPasswordForm.$('email').bind()}
          size="large"
          prefix={<Icon type="user" />}
          placeholder={t('email')}
        />
      </FormItem>
      <FormItemStyled>
        <Button type="primary" onClick={sendResetPasswordEmail} size="large" block>
          {t('sendResetPasswordInstruction')}
        </Button>
      </FormItemStyled>
    </FormStyled>
  );
});

export const ResetPasswordFormContainer = compose(
  inject(({ store: { authStore, authModalStore } }) => ({
    hideModal: authModalStore.hideModal,
    sendResetPasswordForm: authStore.sendResetPasswordForm,
    sendResetPasswordEmail: authStore.sendResetPasswordEmail,
  })),
)(SendResetPasswordForm);
