import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  HeaderStyled,
  HeaderInnerStyled,
  MenuStyled,
  LoggedMenuItemStyled,
  HeaderRightStyled,
  ToggleMenuStyled,
  LoginBtnStyled,
  AvatarStyled,
  AccountStyled,
} from './Header.styles';
import { Url } from '../../../../index';
import { EventUrls } from '../../../../../routes/events/url';
import { EntryUrls } from '../../../../../routes/entries/url';
import { MultiLanguagesContainer } from '../../../languages/MultiLanguages';

const NavLinkWrapper = props => <NavLink exact activeClassName="selected" {...props} />;

const LoggedInMenu = ({ name, logoutUser }) => {
  const [t] = useTranslation(['common']);
  return (
    <div>
      <LoggedMenuItemStyled>
        <AccountStyled>{name}</AccountStyled>
      </LoggedMenuItemStyled>
      <LoggedMenuItemStyled as={NavLinkWrapper} to={EntryUrls.create}>
        {t(`header.menu.createExpertProfile`)}
      </LoggedMenuItemStyled>
      <LoggedMenuItemStyled as={NavLinkWrapper} to={EntryUrls.createBusiness}>
        {t(`header.menu.createBusiness`)}
      </LoggedMenuItemStyled>
      <LoggedMenuItemStyled as={NavLinkWrapper} to={EventUrls.create}>
        {t(`header.menu.createEvent`)}
      </LoggedMenuItemStyled>
      <LoggedMenuItemStyled onClick={logoutUser}> {t(`header.menu.logoutText`)}</LoggedMenuItemStyled>
    </div>
  );
};

const Header = ({ showLogin, isLoggedIn, user, logoutUser }) => {
  const [t] = useTranslation(['common']);
  const [hidden, setHidden] = useState(true);

  return (
    <HeaderStyled>
      <HeaderInnerStyled>
        <Link to={Url.home} />
        <HeaderRightStyled>
          <MenuStyled hiddenWhenSmall={hidden}>
            <MultiLanguagesContainer />

            {isLoggedIn ? (
              <Popover
                content={<LoggedInMenu name={user.name || user.email} logoutUser={logoutUser} />}
                placement="bottom"
              >
                <AvatarStyled src={user.avatar} />
              </Popover>
            ) : (
              <LoginBtnStyled type="primary" onClick={showLogin}>
                {t(`header.menu.loginText`)}
              </LoginBtnStyled>
            )}
          </MenuStyled>
          {hidden && <ToggleMenuStyled type="menu" onClick={() => setHidden(false)} />}
          {!hidden && <ToggleMenuStyled type="close" onClick={() => setHidden(true)} />}
        </HeaderRightStyled>
      </HeaderInnerStyled>
    </HeaderStyled>
  );
};

export const HeaderContainer = compose(
  inject(({ store: { authStore, authModalStore } }) => ({
    showLogin: authModalStore.showLogin,
    isLoggedIn: authStore.isLoggedIn,
    logoutUser: authStore.logoutUser,
    user: authStore.user,
  })),
  observer,
)(Header);
