import styled, { keyframes } from 'styled-components';

const FadeInBottomKeyFrames = keyframes`
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
     opacity: 1
      transform: translateY(0);
     }

`;

export const FadeIn = styled.div`
  animation:
  ${props => {
    switch (props.type) {
      case 'bottom':
        return FadeInBottomKeyFrames;
      case 'none':
        return 'none';
      default:
        return FadeInBottomKeyFrames;
    }
  }}
 animation-duration: ${props => (props.duration ? `${props.duration}s` : '0.5s')};
 animation-timing-function: ${props => (props.timingFunction ? props.timingFunction : 'linear')};
 animation-delay:   ${props => (props.delay ? `${props.delay}s` : '0s')};
`;
