import { stringify } from 'query-string';
import { CONFIGS } from '../../shared/config';

const VIETSEARCH_URL = CONFIGS.API_PREFIX;

export const EventsApis = {
  eventSearch: (what, where, after, before, catIds, countryCode, sort, lang, size, start) =>
    `${VIETSEARCH_URL}/events/search?${stringify({
      what,
      where,
      after,
      before,
      cat_ids: catIds,
      country_code: countryCode,
      sort_by: sort,
      lang,
      size,
      start,
    })}`,
  eventCreate: `${CONFIGS.DATA_API_PREFIX}/event`,
  eventUpdate: id => `${CONFIGS.DATA_API_PREFIX}/events/${id}`,
  eventDelete: id => `${CONFIGS.DATA_API_PREFIX}/events/${id}`,
  eventUploadImage: `${CONFIGS.STORAGE_API_PREFIX}/images`,
  eventDetail: id => `${VIETSEARCH_URL}/events/${id}`,
  eventCategoryTabs: (categorySize, countrycodeSize) =>
    `${VIETSEARCH_URL}/events/search?lang=vi&facets=category:${categorySize},country_code:${countrycodeSize}&size=0`,
  eventExpertSuggetion: (id, model, size, lang) =>
    `${CONFIGS.API_PREFIX}/events/expert_suggestions?${stringify({ event_id: id, model, size, lang })}`,
};
