import React from 'react';
import styled from 'styled-components';
import { Icon, Button, Avatar } from 'antd';
import { lighten } from 'polished';

import { Breakpoints, Colors, Container } from '../../../index';

export const LogoStyled = styled.div`
  height: 40px;
  margin: 5px;
  display: block;
  text-align: center;
`;

export const HeaderStyled = styled.div`
  background-color: ${Colors.white};
  position: relative;
  z-index: 3;
`;

export const HeaderInnerStyled = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MenuStyled = styled.div`
  width: 100%;
  display: ${p => (p.hiddenWhenSmall ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 100%;
  left: 0;
  background: ${Colors.white};
  border-top: 1px solid ${Colors.gray3};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;

  ${Breakpoints.medium} {
    display: flex;
    box-shadow: none;
    border-top: none;
    align-items: center;
    justify-content: space-between;
    width: auto;
    flex-direction: row;
    position: unset;
    padding-bottom: 0;
  }
`;

export const MenuItemStyled = styled.div`
  font-size: 14px;
  padding: 5px 10px;
  color: ${Colors.black};
  font-weight: 600;

  &.selected {
    color: ${Colors.primaryColor};
  }

  :hover {
    color: ${Colors.black};
    border-bottom-color: ${lighten(0.2, Colors.primaryColor)};
  }

  ${Breakpoints.medium} {
    border-bottom: 3px solid transparent;
  }
`;

export const LoggedMenuItemStyled = styled.div`
  font-size: 14px;
  padding: 5px 10px;
  color: ${Colors.black};
  font-weight: 600;
  cursor: pointer;
  display: block;

  &.selected {
    color: ${Colors.primaryColor};
  }

  :hover {
    color: ${Colors.primaryColor};
  }
`;

export const HeaderRightStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const ToggleMenuStyled = styled(Icon)`
  font-size: 20px !important;

  ${Breakpoints.medium} {
    display: none !important;
  }
`;

export const LoginBtnStyled = styled(Button)`
  margin-left: 10px;
  margin-right: 10px;
`;

const WrapperAvatar = ({ ...props }) => <Avatar style={{ backgroundColor: Colors.green }} icon="user" {...props} />;

export const AvatarStyled = styled(WrapperAvatar)`
  cursor: pointer;
`;

export const AccountStyled = styled.div`
  color: ${Colors.green};
`;

export const Logout = styled(WrapperAvatar)`
  cursor: pointer;
`;
export const SettingItemStyled = styled.div`
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0px 0px 0px 10px;
`;
