import { isEmpty, isNumber } from 'lodash';
import i18n from './components/languages/i18n';

i18n.loadNamespaces('validation');

export function validateNotEmpty({ field }) {
  if (!field.value) return [false, i18n.t('validation:notEmpty')];
  return true;
}

export function validateMinLength(minLength) {
  return ({ field }) => {
    if (isEmpty(field.value)) return true;

    if (field.value.length < minLength) return [false, i18n.t('validation:minLength', { minLength })];
    return true;
  };
}

export function validateMaxLength(maxLength) {
  return ({ field }) => {
    if (isEmpty(field.value)) return true;

    if (field.value.length > maxLength) return [false, i18n.t('validation:maxLength', { maxLength })];
    return true;
  };
}

export function validateAgreePublish({ field }) {
  if (!field.value) return [false, i18n.t('validation:notAgreePublish')];
  return true;
}

export function validateIsNumber({ field }) {
  if (!isNumber(field.value)) return [false, i18n.t('validation:isNumber')];
  return true;
}

export function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

export const ruleRequired = status => {
  return {
    required: status || true,
    message: i18n.t('validation:notEmpty'),
  };
};

export const ruleMin = min => {
  return {
    min,
    message: i18n.t('validation:minLength', { minLength: min }),
  };
};

export const ruleMax = max => {
  return {
    max,
    message: i18n.t('validation:minLength', { maxLength: max }),
  };
};

export const ruleAgreePublish = (rule, value, callback) => {
  if (value) {
    return callback();
  }
  return callback(i18n.t('validation:notAgreePublish'));
};
