import { Layout, Menu, Icon, Tooltip } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import SubMenu from 'antd/es/menu/SubMenu';
import { useTranslation } from 'react-i18next';
import { LogoStyled } from '../header/Header.styles';
import { Logo } from '../../../logo/Logo';
import { Url } from '../../../../index';
import { UserUrls } from '../../../../../routes/user/url';
import { MyEntriesUrls } from '../../../../../routes/my-entries/url';
import { EntryUrls } from '../../../../../routes/entries/url';
import { CategoryUrls } from '../../../../../routes/category/url';
import { OrganizationUrls } from '../../../../../routes/organization/url';
import { ProfessionsUrls } from '../../../../../routes/professions/url';
import { TopicsUrls } from '../../../../../routes/topics/url';
import { AlgorithmsUrls } from '../../../../../routes/algorithms/url';
import { CrawlUrls } from '../../../../../routes/crawl/url';

export const Sider = ({ pathname }) => {
  const [t] = useTranslation(['common']);

  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout.Sider
      width="250"
      trigger={null}
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      theme="light"
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
      }}
    >
      <Link to={Url.home}>
        <LogoStyled>
          <Logo />
        </LogoStyled>
      </Link>
      <Menu theme="light" mode="inline" defaultSelectedKeys={['1']} selectedKeys={[pathname]}>
        <Menu.Item key={Url.home}>
          <Link to={Url.home}>
            <Icon type="dashboard" />
            <span>Dashboard</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={UserUrls.page}>
          <Link to={UserUrls.page}>
            <Icon type="user" />
            <span>{t('common:user')}</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={EntryUrls.experts}>
          <Link to={EntryUrls.experts}>
            <Icon type="credit-card" />
            <span>{t('common:header.menu.expertText')}</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={EntryUrls.business}>
          <Link to={EntryUrls.business}>
            <Icon type="global" />
            <span>
              {t('common:header.menu.business')}, {t('common:header.menu.serviceText')}
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key={Url.events}>
          <Link to={Url.events}>
            <Icon type="video-camera" />
            <span>{t('common:header.menu.eventText')}</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={CategoryUrls.page}>
          <Link to={CategoryUrls.page}>
            <Icon type="apartment" />
            <span>{t('common:header.menu.categories')}</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={OrganizationUrls.page}>
          <Link to={OrganizationUrls.page}>
            <Icon type="book" />
            <span>{t('common:header.menu.organizations')}</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={ProfessionsUrls.page}>
          <Link to={ProfessionsUrls.page}>
            <Icon type="account-book" />
            <span>{t('common:header.menu.professions')}</span>
          </Link>
        </Menu.Item>
        <Menu.Item key={TopicsUrls.page}>
          <Link to={TopicsUrls.page}>
            <Icon type="audit" />
            <span>{t('common:header.menu.topics')}</span>
          </Link>
        </Menu.Item>
        <SubMenu
          key={CrawlUrls.person}
          title={
            <span>
              <Icon type="download" />
              <span>Crawl</span>
            </span>
          }
        >
          <Menu.Item key={CrawlUrls.person}>
            <Link to={CrawlUrls.person}>
              <Icon type="user" />
              <span>Crawl {t('common:header.menu.expertText')}</span>
            </Link>
          </Menu.Item>
        </SubMenu>

        <Menu.Item key={AlgorithmsUrls.page}>
          <Link to={AlgorithmsUrls.page}>
            <Icon type="camera" />
            <span>{t('common:header.menu.algorithms')}</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="news">
          <Tooltip title="Tính năng đang phát triển">
            <Link to="/">
              <Icon type="read" />
              <span>{t('common:header.menu.news')}</span>
            </Link>
          </Tooltip>
        </Menu.Item>
        <SubMenu
          key={MyEntriesUrls.page}
          title={
            <span>
              <Icon type="upload" />
              <span>{t('common:header.menu.postsAndEntries')}</span>
            </span>
          }
        >
          <Menu.Item key={MyEntriesUrls.experts}>
            <Link to={MyEntriesUrls.experts}>
              <Icon type="user" />
              <span>{t('common:header.menu.expertText')}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={MyEntriesUrls.business}>
            <Link to={MyEntriesUrls.business}>
              <Icon type="global" />
              <span>
                {t('common:header.menu.serviceText')}, {t('common:header.menu.business')}
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key={MyEntriesUrls.events}>
            <Link to={MyEntriesUrls.events}>
              <Icon type="camera" />
              <span>{t('common:header.menu.eventText')}</span>
            </Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Layout.Sider>
  );
};
export const SiderContainer = compose(
  inject(({ store: { routerStore } }) => ({
    pathname: routerStore.location.pathname,
  })),
  observer,
)(Sider);
