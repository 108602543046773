import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { InputAutoComplete } from '../index';

export const InputPlaceContainer = compose(
  inject(({ store: { placeInputStore, vietsearchStore } }) => {
    const [t] = useTranslation(['common']);
    return {
      value: placeInputStore.value,
      dataSource: placeInputStore.items,
      onChange: placeInputStore.onChange,
      onPressEnter: vietsearchStore.searchWithoutFacetsAndUpdateParams,
      icon: 'pushpin',
      placeholder: t('searchPlaceHolders.place'),
    };
  }),
  observer,
)(InputAutoComplete);
