import { action, observable, reaction } from 'mobx';
import { formatDistance, format } from 'date-fns';
import { vi, enUS, fr } from 'date-fns/locale';
import axios from 'axios';
import { EventsApis } from '../api';
import { EventUrls } from '../url';
import { openErrorNotification, openSuccessNotification } from '../../../shared/components';
import i18n from '../../../shared/components/languages/i18n';

i18n.loadNamespaces('event');

export class EventDetailStore {
  @observable eventInfor = {};

  @observable eventList = [];

  @observable listExpertSuggest = [];

  @observable zoom = undefined;

  @observable.shallow center = undefined;

  @observable.shallow bounds = undefined;

  @observable isShowMap = true;

  constructor(store) {
    this.store = store;
  }

  reaction() {
    reaction(
      () => this.store.routerStore.location,
      ({ pathname }) => {
        const Id = this.getIdFromUrl(pathname);
        if (pathname === EventUrls.getDetailPath(Id)) {
          this.getEventInfor(Id);
        }
      },
    );
  }

  @action.bound
  deleteEvent = id => {
    axios
      .delete(EventsApis.eventDelete(id), this.store.authStore.getUserAuthorizationHeaders())
      .then(() => {
        this.store.routerStore.push({
          pathname: EventUrls.page,
        });
        openSuccessNotification(i18n.t('event:deleteEventSuccess'));
      })
      .catch(
        action('delete event failed', () => {
          openErrorNotification(i18n.t('event:deleteEventFail'));
        }),
      );
  };

  @action.bound
  getEventInfor = id => {
    axios
      .get(EventsApis.eventDetail(id))
      .then(res => res.data)
      .then(
        action('fetch catgory success', ({ entry }) => {
          let newEntry;
          if (entry.location) {
            newEntry = { ...entry, lat: entry.location.lat, lng: entry.location.lon, address: entry.address.displayed };
          } else {
            newEntry = { ...entry, lat: 0, lng: 0, address: entry.address.displayed };
          }
          if (newEntry.start_datetime) {
            const startDateTime = new Date(newEntry.start_datetime);
            const endDateTime = new Date(newEntry.end_datetime);
            const { locale } = this.store.languagesStore;
            let localeDate;
            switch (locale) {
              case 'en':
                localeDate = enUS;
                break;
              case 'fr':
                localeDate = fr;
                break;
              default:
                localeDate = vi;
            }
            newEntry.startdatetimeString = format(startDateTime, 'PPPP', { locale: localeDate });
            newEntry.startDatetime = formatDistance(startDateTime, new Date(), { locale: localeDate });
            newEntry.startTime = format(startDateTime, 'HH:mm', { locale: localeDate });
            if (startDateTime.getDate() !== endDateTime.getDate()) {
              newEntry.endDateTimeString = `${format(endDateTime, 'PP', { locale: localeDate })} ${i18n.t(
                'event:at',
              )} ${format(endDateTime, 'HH:mm', { locale: localeDate })}`;
            } else {
              newEntry.endDateTimeString = `${format(endDateTime, 'HH:mm', { locale: localeDate })}`;
            }
          }
          if (newEntry.contacts) {
            const website = newEntry.contacts.filter(item => item.type === 'website');
            if (website.length > 0) {
              newEntry.website = website[0].value.trim();
            }
          }

          this.eventInfor = newEntry;
          this.eventList = [newEntry];
          this.setActiveItem(newEntry);
          // this.refreshBounds();
        }),
      )
      .catch(
        action('get items fail', () => {
          this.isLoading = false;
        }),
      );
  };

  // #region map

  @action.bound
  onToggleMap = () => {
    this.isShowMap = !this.isShowMap;
    this.setActiveItem(this.eventInfor);
  };

  @action.bound
  onZoomChange = zoom => {
    this.zoom = zoom;
  };

  @action.bound
  onBoundsChange = bounds => {
    this.bounds = bounds;
  };

  @action.bound
  setActiveItem = activeItem => {
    this.activeItem = activeItem;
    if (activeItem) {
      this.onCenterChange({ lat: activeItem.lat, lng: activeItem.lng });
      this.onZoomChange(10);
    }
  };

  @action.bound
  onCenterChange = center => {
    this.center = center;
  };

  onMapLoaded = ({ map, maps }) => {
    this.map = map;
    this.maps = maps;
    this.refreshBounds();
  };

  refreshBounds = () => {
    if (!this.maps) return;
    const bounds = new this.maps.LatLngBounds();
    this.eventList.forEach(item => {
      bounds.extend(new this.maps.LatLng(item.lat, item.lng));
    });
    this.map.fitBounds(bounds);
  };
  // #endregion

  getIdFromUrl = path => {
    const id = path.substring(path.lastIndexOf('/') + 1);
    return id || '';
  };

  fetchListExpertSuggest = id => {
    const { locale } = this.store.languagesStore;
    const size = 10;
    const model = 'mlt'; // model : tfidf, mlt
    axios
      .get(EventsApis.eventExpertSuggetion(id, model, size, locale))
      .then(res => res.data)
      .then(
        action('fetch experts success', resData => {
          const { experts } = resData;
          this.listExpertSuggest = experts;
        }),
      )
      .catch(
        action('get items fail', e => {
          console.log(e);
        }),
      );
  };
}
