import React from 'react';
import { Spring } from 'react-spring/renderprops';
import { isNil } from 'lodash';

export const Scroll = ({ fromScrollY, toScrollY }) => {
  if (isNil(fromScrollY) || isNil(toScrollY)) return null;

  const from = { scrollY: fromScrollY };
  const to = { scrollY: toScrollY };

  return (
    <Spring reset from={from} to={to}>
      {props => {
        window.scrollTo(0, props.scrollY);
        return null;
      }}
    </Spring>
  );
};
