import { action, observable, reaction } from 'mobx';
import i18n from './i18n';

export const languages = ['vi', 'en'];

export class LanguagesStore {
  @observable locale = i18n.language || window.localStorage.i18nextLng;

  constructor(store) {
    this.store = store;
  }

  reaction() {
    reaction(
      () => this.locale,
      locale => {
        i18n.changeLanguage(locale);
      },
    );
  }

  @action.bound
  setLocale = value => {
    this.locale = value;
  };
}
