import { action, observable, reaction } from 'mobx';
import axios from 'axios';
import { APIS } from './apis';
import { Url } from '../shared';

export class HomePageStore {
  @observable expertCategories = [];

  @observable businessCategories = [];

  @observable topEvents = [];

  @observable topNews = [];

  @observable topAssociations = [];

  constructor(store) {
    this.store = store;
  }

  reaction() {
    reaction(
      () => this.store.routerStore.location,
      ({ pathname }, inReaction) => {
        if (pathname === Url.home || pathname === Url.vietSearch || pathname === Url.services) {
          this.fetchData().then(() => {
            inReaction.dispose();
          });
        }
      },
    );
  }

  fetchData() {
    return axios
      .get(APIS.homepage)
      .then(res => res.data)
      .then(
        action('fetch homepage success', data => {
          this.expertCategories = data.sections.expert_categories;
          this.businessCategories = data.sections.business_categories;
          this.topEvents = data.sections.top_events;
          this.topNews = data.sections.top_news;
          this.topAssociations = data.sections.top_associations;
        }),
      );
  }
}
