import React from 'react';
import { Icon } from 'antd';

export const A = ({ newTab, children, ...inProps }) => {
  const props = {
    target: newTab ? '_blank' : undefined,
    ...inProps,
  };

  return <a {...props}>{children}</a>;
};

A.defaultProps = {
  newTab: false,
};

export const AIcon = ({ type, theme, ...inProps }) => {
  return (
    <A {...inProps}>
      <Icon type={type} theme={theme} />
    </A>
  );
};
