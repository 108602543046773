import React from 'react';
import { Layout } from 'antd';
import { HeaderContainer } from './header/Header';
import { Footer } from './footer/Footer';
import { MainStyled } from './MainLayout.styles';
import { SiderContainer } from './sider/Sider';
import { ProtectedContainer } from '../../shared';

const { Content } = Layout;
export const MainLayout = ({ children }) => {
  return (
    <ProtectedContainer message="VietSearch Admin">
    <MainStyled>
      <Layout>
        <SiderContainer />
        <Content style={{ marginLeft: 250 }}>
          <HeaderContainer />
          {children}
          <Footer />
        </Content>
      </Layout>
    </MainStyled>
   </ProtectedContainer>
  );
};
