import { stringify } from 'query-string';
import { CONFIGS } from '../shared/config';

const VIETSEARCH_URL = CONFIGS.API_PREFIX;
const ROOT_API = CONFIGS.NODE_API_PREFIX;
const { HOST_NAME } = CONFIGS;

export const APIS = {
  homepage: `${VIETSEARCH_URL}/homepage`,
  entrySearch: (what, where, types, latlon, category, size, start, lang, radius, countryCode, status, sort, order) =>
    `${VIETSEARCH_URL}/entries/search?${stringify(
      {
        what,
        where,
        types,
        latlon,
        size,
        start,
        cat_ids: category,
        lang,
        radius,
        country_code: countryCode,
        status,
        sort_by: sort,
        order_by: order,
      },
      {
        skipEmptyString: true,
        skipNull: true,
      },
    )}`,
  findWhatByRadius: (latlon, radius, types) =>
    `${VIETSEARCH_URL}/entries/search?${stringify({
      latlon,
      radius,
      types,
    })}`,
  autoCompleteWhat: (what, lang) => `${VIETSEARCH_URL}/suggestions/what?${stringify({ what, size: 10, lang })}`,
  autoCompletePlaces: (where, lang) => `${VIETSEARCH_URL}/suggestions/where?${stringify({ where, lang })}`,
  autoCompleteCategory: (value, lang, types) =>
    `${VIETSEARCH_URL}/categories/suggest?${stringify({ q: value, lang, types })}`,
  detail: id => `${VIETSEARCH_URL}/entries/${id}`,
  registerUser: `${ROOT_API}/user/register`,
  loginUser: `${ROOT_API}/user/login`,
  infoUser: `${ROOT_API}/user/info`,
  logoutUser: `${ROOT_API}/user/logout`,
  loginFacebook: `${ROOT_API}/auth/facebook/token`,
  loginGoogle: `${ROOT_API}/auth/google/token`,
  config: `${ROOT_API}/config`,
  verifyUser: token => `${ROOT_API}/user/verify/${token}`,
  sendVerifyEmail: `${ROOT_API}/user/send-verify-email`,
  sendResetPasswordEmail: `${ROOT_API}/user/send-reset-password-email`,
  resetPassword: `${ROOT_API}/user/reset-password`,
  shareFacebook: url => `https://www.facebook.com/sharer/sharer.php?u=${HOST_NAME}${url}`,
  shareTwitter: text => `https://twitter.com/intent/tweet?text=${HOST_NAME}${text}`,
};
