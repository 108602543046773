import { stringify } from 'query-string';

export const EntryPath = '/entries';
export const EntryUrls = {
  create: `${EntryPath}/create/expert`,
  createBusiness: `${EntryPath}/create/business`,
  update: `${EntryPath}/update/:id`,
  updateBusiness: `${EntryPath}/update/business/:id`,
  detail: `${EntryPath}/detail/:id`,
  page: `${EntryPath}/:type`,
  experts: `${EntryPath}/experts`,
  business: `${EntryPath}/business`,
  getDetailPath: id => `${EntryPath}/detail/${id}`,
  getUpdatePath: id => `${EntryPath}/update/${id}`,
  getUpdateBusinessPath: id => `${EntryPath}/update/business/${id}`,
  getSearchPath: (category, what = '', where = '', lang = 'vi', types = 'person') =>
    `${EntryPath}?${stringify({ what, where, findType: types, category, lang })}`,
};
