import { createGlobalStyle } from 'styled-components';

export const AppStyled = createGlobalStyle`
  body {
    @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');
    background-color: white;
    font-family: 'Open Sans', sans-serif;
    color: #484848;
  }

  .ant-carousel .slick-slide {
    text-align: center;
    overflow: hidden;
    z-index: 4;
    border-radius: 10px;
  }
`;
