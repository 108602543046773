import React from 'react';
import { useTranslation } from 'react-i18next';
import { FooterStyled, FooterInnerStyled, LogoStyled, RightLabelStyled, RightBlockStyled } from './Footer.styles';

import { Logo } from '../../../logo/Logo';

export const Footer = () => {
  const [t] = useTranslation(['common']);
  return (
    <FooterStyled>
      <FooterInnerStyled>
        <RightBlockStyled>
          <LogoStyled>
            <Logo compact />
          </LogoStyled>
          <RightLabelStyled>{t('footer.rightText')}</RightLabelStyled>
        </RightBlockStyled>
      </FooterInnerStyled>
    </FooterStyled>
  );
};
