import React from 'react';
import { Router } from 'react-router-dom';
import { ScrollContainer, AuthModalContainer } from '../shared/components/shared';
import { Url } from '../shared';
import { Home } from './home/Home';
import { UsersLazy, UserPath } from './user';
import { LazyRoute } from './LazyRoute';
import { EventsRoutesLazy, EventPath } from './events';
import { EntriesRoutesLazy, EntryPath } from './entries';
import { JobRoutesLazy, JobPath } from './jobs';
import { ExpertsRoutesLazy, ExpertPath } from './experts';
import { MyEntriesRoutesLazy, MyEntriesPath } from './my-entries';
import { CategoryRoutesLazy, CategoryPath } from './category';
import { OrganizationPath } from './organization/url';
import { OrganizationRoutesLazy } from './organization/lazy';
import { ProfessionsPath } from './professions/url';
import { ProfessionsRoutesLazy } from './professions/lazy';
import { TopicsPath } from './topics/url';
import { TopicsRoutesLazy } from './topics/lazy';
import { AlgorithmsUrls } from './algorithms/url';
import { AlgorithmsRoutesLazy } from './algorithms/lazy';
import { CrawlPath } from './crawl/url';
import { CrawlRoutesLazy } from './crawl/lazy';

export function Routes({ history }) {
  return (
    <>
      <Router history={history}>
        <LazyRoute path={Url.home} exact LazyComponent={Home} />
        <LazyRoute path={UserPath} LazyComponent={UsersLazy} />
        <LazyRoute path={EventPath} LazyComponent={EventsRoutesLazy} />
        <LazyRoute path={EntryPath} LazyComponent={EntriesRoutesLazy} />
        <LazyRoute path={JobPath} LazyComponent={JobRoutesLazy} />
        <LazyRoute path={ExpertPath} LazyComponent={ExpertsRoutesLazy} />
        <LazyRoute path={MyEntriesPath} LazyComponent={MyEntriesRoutesLazy} />
        <LazyRoute path={CategoryPath} LazyComponent={CategoryRoutesLazy} />
        <LazyRoute path={OrganizationPath} LazyComponent={OrganizationRoutesLazy} />
        <LazyRoute path={ProfessionsPath} LazyComponent={ProfessionsRoutesLazy} />
        <LazyRoute path={TopicsPath} LazyComponent={TopicsRoutesLazy} />
        <LazyRoute path={AlgorithmsUrls} LazyComponent={AlgorithmsRoutesLazy} />
        <LazyRoute path={CrawlPath} LazyComponent={CrawlRoutesLazy} />
      </Router>
      <ScrollContainer />
      <AuthModalContainer />
    </>
  );
}
