import styled from 'styled-components';
import { Colors, Breakpoints } from '../../index';

export const WrapperStyled = styled.div`
  margin: 20px 0;
`;

export const TopBusinessCardStyled = styled.div`
  cursor: pointer;
  transition: 0.3s;
  max-width: 120px;
  border: 1px solid ${Colors.gray3};
  background: ${Colors.white};
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
  }

  ${Breakpoints.medium} {
    max-width: 160px;
  }
`;

export const TopBusinessImgStyled = styled.img`
  max-height: 120px;
  width: 100%;

  ${Breakpoints.medium} {
    max-height: 160px;
  }
`;

export const TitleStyled = styled.div`
  padding: 5px;
  text-align: center;
  font-weight: bold;
  color: ${Colors.black1};
  overflow: hidden;
  text-overflow: ellipsis;
`;
