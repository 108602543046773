const ColorsPlate = {
  black: '#000a12',
  orange: '#FF8800',
  black1: '#484848',
  black2: '#455a6d',
  gray: '#f2f2f2',
  gray1: '#ebebeb',
  gray3: '#dddddd',
  gray2: '#767676',
  blue1: '#1890ff',
  blue2: '#2CA6D1',
  green: '#73ba24',
  white: '#ffffff',
  red: '#FF5A5F',
  primaryColor: '#2CA6D1',
  primaryColorDark: '#2b81ab',
  facebook: '#4267b2',
};

export const Colors = {
  ...ColorsPlate,
  textSecondary: ColorsPlate.gray2,
};
