import React from 'react';
import styled from 'styled-components';
import { Colors } from '../styles/Color.styles';
import { H4 } from '../elements/H.styles';

const WrapperStyled = styled.div`
  border-radius: 10px;
  background: transparent;
  margin-bottom: 20px;
`;

const TitleStyled = styled.div`
  padding-left: 20px;
  padding-top: 10px;
  text-align: left;

  h4 {
    margin-bottom: 0;
    color: ${Colors.primaryColor};
    border-bottom: 1px solid ${Colors.primaryColor};
    display: inline-block;
  }
`;

export const Board = ({ title, children }) => (
  <WrapperStyled>
    <TitleStyled>
      <H4>{title}</H4>
    </TitleStyled>
    {children}
  </WrapperStyled>
);
