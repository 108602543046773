import React from 'react';
import { Chart } from 'react-google-charts';

export const DiviceByUser = () => {
  return (
    <Chart
      width="100%"
      height="400px"
      chartType="PieChart"
      loader={<div>Loading Chart</div>}
      data={[
        ['device', 'Device by user'],
        ['Desktop', 1000],
        ['Mobile', 500],
      ]}
      options={{
        title: 'Device by user',
      }}
      rootProps={{ 'data-testid': '1' }}
    />
  );
};
