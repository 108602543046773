import { action, observable } from 'mobx';
import MobxReactForm from 'mobx-react-form';
import axios from 'axios';
import vjf from 'mobx-react-form/lib/validators/VJF';
import { UserApi } from '../api';
import { openErrorNotification, openSuccessNotification } from '../../../shared/components';
import i18n from '../../../shared/components/languages/i18n';

const plugins = {
  vjf: vjf(),
};

const options = {
  showErrorsOnReset: false,
};

const userFields = [
  {
    name: 'email',
    label: 'Email',
  },
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'avatar',
    label: 'Avatar',
  },
];

export class UserCrudStore {
  userForm = new MobxReactForm({ fields: userFields }, { plugins, options });

  @observable user = null;

  @observable id = null;

  @observable roles = [];

  constructor(store) {
    this.store = store;
  }

  @action.bound
  setId = id => {
    this.reset();
    this.id = id;
    axios
      .get(UserApi.detail(id), this.store.authStore.getUserAuthorizationHeaders())
      .then(res => res.data)
      .then(
        action('fetch user success', user => {
          this.userForm.update(user);
          this.user = user;
          this.roles = user.roles;
        }),
      );
  };

  @action.bound
  reset = () => {
    this.userForm.reset();
    this.id = null;
    this.user = null;
    this.roles = [];
  };

  @action.bound
  onChangeRoles = checkedList => {
    this.roles = checkedList;
  };

  @action.bound
  updateUser = () => {
    this.postUser(this.user, UserApi.update(this.id));
  };

  postUser = (data, api) => {
    this.userForm.submit({
      onSuccess: () => {
        const user = {
          ...data,
          roles: this.roles,
        };
        axios
          .post(api, user, this.store.authStore.getUserAuthorizationHeaders())
          .then(res => res.data)
          .then(
            action('post user success', user => {
              openSuccessNotification('Cập nhật thành công');
              this.store.userModalStore.hideModal();
              this.store.userPageStore.fetchUsers();
              this.reset();
            }),
          )
          .catch(
            action('post user failed', () => {
              openErrorNotification('Cập nhật thất bại!');
            }),
          );
      },
      onError: action('validation failed', () => {
        openErrorNotification(i18n.t('Định dạng sai!'));
      }),
    });
  };
}
