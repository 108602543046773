import axios from 'axios';
import { isEmpty, remove } from 'lodash';
import { action, observable } from 'mobx';
import queryString from 'querystring';
import { logger } from '../../../shared';
import { EntryApis } from '../api';
import { EntryUrls } from '../url';
import { openErrorNotification, openSuccessNotification } from '../../../shared/components';
import i18n from '../../../shared/components/languages/i18n';

export class EntryPageStore {
  prevPathname = null;

  @observable isLoading = false;

  @observable isFirstLoad = false;

  @observable activeItem = null;

  @observable category = undefined;

  @observable showMapOption = true;

  @observable whatInput = '';

  @observable whereInput = '';

  @observable countryCode = '';

  @observable types = '';

  @observable before = '';

  @observable after = '';

  @observable catIds = '';

  @observable zoom = undefined;

  @observable.shallow center = undefined;

  @observable.shallow bounds = undefined;

  @observable.shallow eventList = [];

  @observable eventByCountryCategory = [];

  @observable eventListCategoryTabs = [];

  @observable eventListTypesTabs = [];

  @observable totalEvent = 0;

  @observable totalCategory = 0;

  @observable eventPage = 1;

  @observable eventPageSize = 10;

  @observable categoryPageSize = 30;

  @observable categoryTopicPageSize = 10;

  constructor(store) {
    this.store = store;
  }

  @action.bound
  loadEntryCategory() {
    const { pathname } = this.store.routerStore.location;
    const { query } = this.store.routerStore;
    this.reset();
    if (pathname === EntryUrls.page) {
      if (!isEmpty(query.what)) {
        this.whatInput = query.what;
      }
      if (!isEmpty(query.where)) {
        this.whereInput = query.where;
        this.store.placeInputStore.value = query.where;
      }
      if (!isEmpty(query.cat_ids)) {
        this.catIds = query.cat_ids;
      }
      if (!isEmpty(query.country_code)) {
        this.countryCode = query.country_code;
      }
      if (!isEmpty(query.types)) {
        this.types = query.types;
      }
      this.fetchListCountryCategory();
      // this.fetchListEvent();
      this.fetchListFilterTab();
      this.fetchListTypesTab();
    }
  }

  @action.bound
  onChaneWhatInput = e => {
    this.whatInput = e.target.value;
  };

  @action.bound
  selectCountry = value => {
    console.log(value);
    this.countryCode = value;
    console.log(this.eventByCountryCategory[0]);
    if (this.eventByCountryCategory.length === 1) {
      this.whereInput = this.eventByCountryCategory[0].value.en;
    }
    this.eventByCountryCategory.forEach(item => {
      if (item.key === this.countryCode) {
        item.active = true;
        this.whereInput = item.value.en;
      } else {
        item.active = false;
      }
    });
    this.eventPage = 1;
    this.searchAndUpdateParams();
  };

  @action.bound
  loadMoreCategoryByCountry = () => {
    this.categoryPageSize += this.categoryPageSize;
    this.fetchListCountryCategory();
  };

  @action.bound
  loadMoreCategoryByTopic = () => {
    this.categoryTopicPageSize += this.categoryTopicPageSize;
    this.fetchListFilterTab();
  };

  @action.bound
  reset = () => {
    this.category = undefined;
    this.whereInput = '';
    this.whatInput = '';
    this.catIds = '';
    this.types = '';
    this.countryCode = '';
  };

  @action.bound
  setCategory = category => {
    this.catIds = category;
    this.store.vietsearchStore.setCategory({
      category,
      name: category,
    });
  };

  @action.bound
  searchAndUpdateParams = () => {
    this.updateUrlSearchParams();
    console.log('search and update params entry');
    this.store.whatStore.fetchListEntries();
  };

  @action.bound
  navigateToVietsearch = () => {
    this.updateUrlSearchParams(false);
  };

  @action.bound
  updateUrlSearchParamsIfOnVietsearch = () => {
    if (this.store.routerStore.pathname === EntryUrls.page) {
      this.updateUrlSearchParams();
    }
  };

  updateUrlSearchParams = (replaced = true) => {
    const updateRoute = replaced ? this.store.routerStore.replace : this.store.routerStore.push;
    updateRoute({
      pathname: EntryUrls.page,
      search: queryString.stringify({
        what: this.whatInput,
        where: this.store.placeInputStore.value,
        cat_ids: this.catIds,
        country_code: this.countryCode,
        types: this.types,
        before: this.before,
        after: this.after,
      }),
    });
  };

  @action.bound
  fetchListCountryCategory = () => {
    this.isLoading = true;
    const size = this.categoryPageSize;
    const what = this.whatInput;
    const where = this.whereInput || this.store.placeInputStore.value;
    axios
      .get(EntryApis.entryCategoryTabs(what, where, this.types, '', 1, size))
      .then(res => res.data)
      .then(
        action('fetch catgory success', ({ total, facets }) => {
          this.isLoading = false;
          this.totalCategory = total.value;
          this.eventByCountryCategory = facets.country_code;

          this.eventByCountryCategory.forEach(item => {
            if (this.countryCode === item.key) {
              item.active = true;
            } else {
              item.active = false;
            }
          });
        }),
      )
      .catch(
        action('get items fail', () => {
          this.isLoading = false;
        }),
      );
  };

  @action.bound
  fetchListFilterTab = () => {
    const pagesize = this.categoryTopicPageSize;
    const what = this.whatInput;
    const where = this.whereInput || this.store.placeInputStore.value;
    axios
      .get(EntryApis.entryCategoryTabs(what, where, this.types, '', pagesize, 1))
      .then(res => res.data)
      .then(
        action('fetch catgory tabs success', ({ facets }) => {
          this.eventListCategoryTabs = facets.category;
          this.eventListCategoryTabs.forEach(item => {
            if (this.catIds === item.key) {
              item.active = true;
            } else {
              item.active = false;
            }
          });
        }),
      )
      .catch(
        action('get items fail', error => {
          logger.log(error);
        }),
      );
  };

  @action.bound
  fetchListTypesTab = () => {
    const what = this.whatInput;
    const where = this.whereInput || this.store.placeInputStore.value;
    axios
      .get(EntryApis.entryCategoryTabs(what, where, this.types, '', 1, 1))
      .then(res => res.data)
      .then(
        action('fetch catgory tabs success', ({ facets }) => {
          this.eventListTypesTabs = facets.types;
          this.eventListTypesTabs.forEach(item => {
            if (this.catIds === item.key) {
              item.active = true;
            } else {
              item.active = false;
            }
          });
        }),
      )
      .catch(
        action('get items fail', error => {
          logger.log(error);
        }),
      );
  };

  @action.bound
  onChangeTabsEvent = key => {
    this.catIds = key;
    this.eventListCategoryTabs.forEach(item => {
      if (item.key === this.catIds) {
        item.active = true;
      } else {
        item.active = false;
      }
    });

    this.eventPage = 1;
    this.searchAndUpdateParams();
  };

  @action.bound
  onChangeTypesEvent = key => {
    this.types = key;
    this.eventListTypesTabs.forEach(item => {
      if (item.key === this.types) {
        item.active = true;
      } else {
        item.active = false;
      }
    });

    this.eventPage = 1;
    this.searchAndUpdateParams();
  };

  setSearchBoxEl = el => {
    this.searchBoxEl = el;
  };

  @action.bound
  deleteEntry = id => {
    axios
      .delete(EntryApis.entryDelete(id), this.store.authStore.getUserAuthorizationHeaders())
      .then(() => {
        remove(this.store.whatStore.items, entry => entry.id === id);
        openSuccessNotification(i18n.t('entry:deleteEntrySuccess'));
      })
      .catch(
        action('delete event failed', () => {
          openErrorNotification(i18n.t('entry:deleteEntryFail'));
        }),
      );
  };
}
