import { action, computed, observable, reaction } from 'mobx';
import axios from 'axios';
import { get } from 'lodash';
import { EntryApis } from '../api';

export class EntryDetailStore {
  @observable relatedExperts = [];

  @observable entry = [];

  @observable entryId = '';

  @observable entryMap = [];

  @observable entriesMap = [];

  @observable zoom = undefined;

  @observable.shallow center = undefined;

  @observable.shallow bounds = undefined;

  @observable activeItem = null;

  @observable isShowLoadMoreExperts = true;

  @observable isLoadExpertSuggestion = false;

  expertSuggestionPage = 1;

  constructor(store) {
    this.store = store;
  }

  reaction() {
    reaction(
      () => this.entryId,
      () => {
        this.fetchEntryDetail(this.entryId);
        this.fetchExpertRelated(this.entryId);
      },
    );
  }

  // #region map
  @computed get activeItemId() {
    return this.activeItem && this.activeItem.id;
  }

  @action.bound
  onToggleMap = () => {
    this.isShowMap = true;
    this.setActiveItem(this.entriesMap);
  };

  @action.bound
  onZoomChange = zoom => {
    this.zoom = zoom;
  };

  @action.bound
  onBoundsChange = bounds => {
    this.bounds = bounds;
  };

  @action.bound
  setActiveItem = activeItem => {
    this.activeItem = activeItem;
    if (activeItem) {
      this.onCenterChange({ lat: activeItem.lat, lng: activeItem.lng });
      this.onZoomChange(10);
    }
  };

  @action.bound
  onCenterChange = center => {
    this.center = center;
  };

  onMapLoaded = ({ map, maps }) => {
    this.map = map;
    this.maps = maps;
    this.refreshBounds();
  };

  refreshBounds = () => {
    if (!this.maps) return;
    const bounds = new this.maps.LatLngBounds();
    this.entriesMap.forEach(item => {
      bounds.extend(new this.maps.LatLng(item.lat, item.lng));
    });
    this.map.fitBounds(bounds);
  };
  // #endregion

  @action.bound
  setEntryId = id => {
    this.entryId = id;
  };

  @action.bound
  fetchEntryDetail = id => {
    axios
      .get(EntryApis.entryDetail(id))
      .then(res => res.data)
      .then(
        action('fetch detail entry success', data => {
          const { entry } = data;
          this.entry = entry;
          let entryMap;
          if (entry.location) {
            entryMap = {
              ...entry,
              id: entry.id,
              name: entry.name,
              title: entry.title,
              address: entry.address.displayed,
              type: entry.types[0],
              thumbnail: entry.thumbnail,
              web: entry.web,
              lng: get(entry, 'location.lon', 0),
              lat: get(entry, 'location.lat', 0),
            };
          } else {
            entryMap = {
              ...entry,
              lat: 0,
              lng: 0,
              address: entry.address.displayed,
            };
          }
          this.entryMap = entryMap;
          this.entriesMap.push(entryMap);
          this.setActiveItem(entryMap);
          this.refreshBounds();
          this.store.vietsearchStore.setFindType(entry.types[0]);
        }),
      )
      .catch(
        action('get items fail', e => {
          console.log(e);
        }),
      );
  };

  @action.bound
  fetchExpertRelated = id => {
    this.getListExpertSuggest(id)
      .then(data => {
        this.relatedExperts = data;
      })
      .catch(
        action('get items fail', e => {
          console.log(e);
        }),
      );
  };

  @action.bound
  getListExpertSuggest = id => {
    return new Promise((resolve, reject) => {
      const { locale } = this.store.languagesStore;
      const size = 10;
      const start = (this.expertSuggestionPage - 1) * size;
      this.isLoadExpertSuggestion = true;
      axios
        .get(EntryApis.entryExpertSimilar(id, size, locale, start))
        .then(res => res.data)
        .then(
          action('fetch experts success', resData => {
            const { experts } = resData;
            this.isLoadExpertSuggestion = false;
            resolve(experts);
          }),
        )
        .catch(
          action('get items fail', e => {
            this.isLoadExpertSuggestion = false;
            reject(e);
          }),
        );
    });
  };

  @action.bound
  loadMoreExpertSuggest = () => {
    this.expertSuggestionPage += 1;
    this.getListExpertSuggest(this.entryId).then(listExpert => {
      if (listExpert.length > 0) {
        const listData = [...this.relatedExperts, ...listExpert];
        this.relatedExperts = listData;
      } else {
        this.isShowLoadMoreExperts = false;
      }
    });
  };
}
