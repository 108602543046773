import { stringify } from 'query-string';
import { CONFIGS } from '../../shared/config';

const USER_URL = CONFIGS.USER_PREFIX;

export const UsersApis = {
  entries: (size, start, type, keyword) =>
    `${USER_URL}/entries?${stringify(
      {
        size,
        start,
        type,
        keyword,
      },
      {
        skipEmptyString: true,
        skipNull: true,
      },
    )}`,
  events: (size, start, keyword) =>
    `${USER_URL}/events?${stringify(
      {
        size,
        start,
        keyword,
      },
      {
        skipEmptyString: true,
        skipNull: true,
      },
    )}`,
};
