import React from 'react';
import styled from 'styled-components';
import { Form } from 'antd';
import { observer } from 'mobx-react';
import { Colors } from '../styles/Color.styles';

export const FormItemStyled = styled(Form.Item)`
  margin-bottom: 10px !important;
  position: relative;

  .ant-form-item-control {
    line-height: unset;
  }
  .ant-input:focus {
    background-color: ${Colors.waveShadowColor};
  }

  .ant-input {
    border-left: 2px solid ${Colors.primaryColor};
  }

  .ant-select-selection {
    border-left: 2px solid ${Colors.primaryColor};
  }
`;

export const FormItem = observer(({ children, ...props }) => {
  return <FormItemStyled {...props}>{children}</FormItemStyled>;
});
