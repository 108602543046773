import React from 'react';
import { Chart } from 'react-google-charts';

export const PageUserVisit = () => {
  return (
    <Chart
      width="100%"
      height="400px"
      chartType="Table"
      loader={<div>Loading Chart</div>}
      data={[
        [
          { type: 'string', label: 'Page' },
          { type: 'number', label: 'Views' },
          { type: 'number', label: '%' },
        ],
        ['/entries', { v: 10000, f: '10,000' }, true],
        ['/', { v: 8000, f: '8,000' }, false],
        ['/expert', { v: 12500, f: '12,500' }, true],
        ['/news', { v: 7000, f: '$,000' }, true],
        ['/services', { v: 7000, f: '7,000' }, true],
      ]}
      options={{
        showRowNumber: true,
      }}
      rootProps={{ 'data-testid': '1' }}
    />
  );
};
