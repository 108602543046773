import React from 'react';
import logoSmall from './logo-color.png';
import { LogoStyled } from './Logo.styles';

export const Logo = ({ compact }) => {
  return (
    <>
      <LogoStyled src={compact ? logoSmall : logoSmall} />
    </>
  );
};
