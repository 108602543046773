import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import { Scroll } from '../index';

export const ScrollContainer = compose(
  inject(({ store: { windowStore } }) => ({
    toggled: windowStore.toggled,
    fromScrollY: windowStore.fromScrollY,
    toScrollY: windowStore.toScrollY,
  })),
  observer,
)(Scroll);
