import { action, observable, reaction } from 'mobx';
import axios from 'axios';
import { ExpertApis } from '../api';
import { Url } from '../../../shared/url';
import { ExpertUrls } from '../url';
import { logger } from '../../../shared';

export class ExpertPageStore {
  @observable expertCategories = [];

  @observable expertPersons = [];

  @observable expertEvents = [];

  @observable expertNews = [];

  @observable expertCountries = [];

  @observable expertCountrySize = 30;

  constructor(store) {
    this.store = store;
  }

  reaction() {
    reaction(
      () => this.store.routerStore.location,
      ({ pathname }) => {
        if (pathname === ExpertUrls.page) {
          this.store.vietsearchStore.setFindType('person');
          this.fetchExpertHomePage();
        }
      },
    );
  }

  @action.bound
  searchByCountryCode = countryCode => {
    this.store.routerStore.push({
      pathname: Url.vietSearch,
    });
    this.store.entryPageStore.reset();
    this.store.entryPageStore.selectCountry(countryCode);
  };

  fetchExpertHomePage() {
    return axios
      .get(ExpertApis.homepage)
      .then(res => res.data)
      .then(
        action('fetch expert homepage success', data => {
          this.expertCategories = data.sections.expert_categories;
          this.expertPersons = data.sections.expert_persons;
          this.expertEvents = data.sections.expert_events;
          this.expertNews = data.sections.expert_news;
          this.expertCountries = data.sections.expert_countries;
        }),
      )
      .catch(
        action('get items fail', error => {
          logger.log(error);
        }),
      );
  }
}
