import { action, observable, reaction, computed } from 'mobx';
import axios from 'axios';
import { matchPath } from 'react-router-dom';
import queryString from 'querystring';
import { isEmpty } from 'lodash';
import { endOfDay, subMonths, subYears, startOfDay, isValid, isEqual, addYears } from 'date-fns';
import { addMonths } from 'date-fns/esm';
import { logger } from '../../../shared';
import { EventsApis } from '../api';
import { EventUrls } from '../url';
import { JobUrls } from '../../jobs/url';

export const filterTimeEnum = {
  all: 'all',
  today: 'today',
  from3Month: 'from3Month',
  lastYear: 'lastYear',
};

export class EventPageStore {
  prevPathname = null;

  @observable isLoading = false;

  @observable isFirstLoad = false;

  @observable activeItem = null;

  @observable filterTime = filterTimeEnum.all;

  @observable category = undefined;

  @observable showMapOption = true;

  @observable whatInput = '';

  @observable whereInput = '';

  @observable countryCode = '';

  @observable before = '';

  @observable after = '';

  @observable catIds = '';

  @observable zoom = undefined;

  @observable.shallow center = undefined;

  @observable.shallow bounds = undefined;

  @observable.shallow eventList = [];

  @observable eventByCountryCategory = [];

  @observable eventListCategoryTabs = [];

  @observable totalEvent = 0;

  @observable totalCategory = 0;

  @observable eventPage = 1;

  @observable eventPageSize = 30;

  @observable categoryPageSize = 30;

  @observable categoryTopicPageSize = 10;

  constructor(store) {
    this.store = store;
  }

  reaction() {
    reaction(
      () => this.store.routerStore.location,
      ({ pathname }) => {
        if (pathname !== EventUrls.page) {
          const matchPathJobDetail = matchPath(pathname, {
            path: JobUrls.detail,
          });
          if (pathname === JobUrls.page || matchPathJobDetail) {
            this.fetchListEvent();
          }
          this.reset();
        } else if (pathname !== this.prevPathname) {
          const { query } = this.store.routerStore;
          if (!isEmpty(query.what)) {
            this.whatInput = query.what;
          }
          if (!isEmpty(query.where)) {
            this.whereInput = query.where;
            this.store.placeInputStore.value = query.where;
          }
          if (!isEmpty(query.cat_ids)) {
            this.catIds = query.cat_ids;
          }
          if (!isEmpty(query.country_code)) {
            this.countryCode = query.country_code;
          }
          if (!isEmpty(query.before)) {
            this.before = query.before;
          }
          if (!isEmpty(query.after)) {
            this.after = query.after;
          }
          if (this.before && this.after) {
            const before = new Date(this.before);
            const after = new Date(this.after);
            if (isValid(before) && isValid(after)) {
              if (isEqual(startOfDay(before), startOfDay(after))) {
                this.filterTime = filterTimeEnum.today;
              } else if (isEqual(startOfDay(before), startOfDay(addMonths(after, 3)))) {
                this.filterTime = filterTimeEnum.from3Month;
              } else if (isEqual(startOfDay(before), startOfDay(addYears(after, 1)))) {
                this.filterTime = filterTimeEnum.lastYear;
              }
            }
          }
          this.fetchListCountryCategory();
          this.fetchListEvent();
          this.fetchListFilterTab();
        }
        this.prevPathname = pathname;
      },
    );

    reaction(
      () => this.filterTime,
      filterTime => {
        if (filterTime) {
          this.updateUrlSearchParamsIfOnVietsearch();
        }
      },
    );
  }

  @computed
  get showMap() {
    return this.showMapOption;
  }

  @computed
  get totalFilters() {
    let total = 0;

    if (this.filterTime !== filterTimeEnum.all) {
      total += 1;
    }

    if (this.category) {
      total += 1;
    }
    return total;
  }

  @computed get pagination() {
    return {
      pageSize: this.eventPageSize,
      showSizeChanger: true,
      current: this.eventPage,
      total: this.totalEvent,
    };
  }

  @action.bound
  onCenterChange = center => {
    this.center = center;
  };

  @action.bound
  onChaneWhatInput = e => {
    this.whatInput = e.target.value;
  };

  @action.bound
  onPageChanged = ({ current }) => {
    this.eventPage = current;
    this.fetchListEvent();
    this.store.windowStore.scrollToTop();
  };

  @action.bound
  selectFilterTime = () => {
    this.fetchListEvent();
  };

  @computed get activeItemId() {
    return this.activeItem && this.activeItem.id;
  }

  @action.bound
  onZoomChange = zoom => {
    this.zoom = zoom;
  };

  @action.bound
  onBoundsChange = bounds => {
    this.bounds = bounds;
  };

  onMapLoaded = ({ map, maps }) => {
    this.map = map;
    this.maps = maps;
    this.refreshBounds();
  };

  @action.bound
  setActiveItem = activeItem => {
    this.activeItem = activeItem;
    if (activeItem) {
      this.onCenterChange({ lat: activeItem.lat, lng: activeItem.lng });
      this.onZoomChange(10);
    }
  };

  @action.bound
  selectCountry = value => {
    this.countryCode = value;
    this.eventByCountryCategory = this.eventByCountryCategory.map(item => ({
      ...item,
      active: item.key === this.countryCode,
    }));
    this.eventPage = 1;
    this.searchAndUpdateParams();
  };

  @action.bound
  loadMoreCategoryByCountry = () => {
    this.categoryPageSize += this.categoryPageSize;
    this.fetchListCountryCategory();
  };

  @action.bound
  loadMoreCategoryByTopic = () => {
    this.categoryTopicPageSize += this.categoryTopicPageSize;
    this.fetchListFilterTab();
  };

  @action.bound
  reset = () => {
    this.category = undefined;
    this.filterTime = filterTimeEnum.all;
    this.whereInput = '';
    this.whatInput = '';
    this.catIds = '';
    this.countryCode = '';
  };

  @action.bound
  resetFacets = () => {
    this.catIds = '';
    this.countryCode = '';
  };

  @action.bound
  clearFilter = () => {
    this.resetFacets();
  };

  @action.bound
  setCategory = category => {
    this.category = category;
  };

  @action.bound
  searchAndUpdateParams = () => {
    this.updateUrlSearchParams();
    this.fetchListEvent();
  };

  @action.bound
  onFilterTimeChanged = value => {
    const today = new Date();
    switch (value) {
      case filterTimeEnum.today:
        this.after = startOfDay(today).toISOString();
        this.before = endOfDay(today).toISOString();
        this.setFilterTime(value);
        break;
      case filterTimeEnum.from3Month:
        this.after = startOfDay(subMonths(today, 3)).toISOString();
        this.before = endOfDay(today).toISOString();
        this.setFilterTime(value);
        break;
      case filterTimeEnum.lastYear:
        this.after = startOfDay(subYears(today, 1)).toISOString();
        this.before = endOfDay(today).toISOString();
        this.setFilterTime(value);
        break;
      default:
        this.after = '';
        this.before = '';
        this.setFilterTime(filterTimeEnum.all);
        break;
    }

    this.searchAndUpdateParams();
  };

  @action.bound
  setFilterTime = filterTime => {
    this.filterTime = filterTime;
  };

  @action.bound
  onshowMapOptionChanged = checked => {
    this.showMapOption = checked;
  };

  @action.bound
  navigateToVietsearch = () => {
    this.updateUrlSearchParams(false);
  };

  @action.bound
  updateUrlSearchParamsIfOnVietsearch = () => {
    if (this.store.routerStore.pathname === EventUrls.page) {
      this.updateUrlSearchParams();
    }
  };

  updateUrlSearchParams = (replaced = true) => {
    const updateRoute = replaced ? this.store.routerStore.replace : this.store.routerStore.push;
    updateRoute({
      pathname: EventUrls.page,
      search: queryString.stringify({
        what: this.whatInput,
        where: this.store.placeInputStore.value,
        cat_ids: this.catIds,
        country_code: this.countryCode,
        before: this.before,
        after: this.after,
      }),
    });
  };

  @action.bound
  fetchListEvent = () => {
    this.isLoading = true;
    const what = this.whatInput;
    const where = this.store.placeInputStore.value;
    const sort = 'time';
    const lang = 'vi';
    axios
      .get(
        EventsApis.eventSearch(
          what,
          where,
          this.after,
          this.before,
          this.catIds,
          this.countryCode,
          sort,
          lang,
          this.eventPageSize,
          this.getStart(),
        ),
      )
      .then(res => res.data)
      .then(
        action('fetch items success', ({ total, entries }) => {
          this.isLoading = false;
          this.totalEvent = total.value;
          this.store.placeInputStore.value = where;
          this.eventList = entries.map((resItem, index) => {
            const location = resItem.location || { lat: 0, lon: 0 };

            return {
              ...resItem,
              index: index + 1,
              id: resItem.id,
              name: resItem.name,
              title: resItem.title,
              address: resItem.address.displayed,
              type: resItem.types[0],
              thumbnail: resItem.thumbnail,
              web: resItem.web,
              lng: location.lon,
              lat: location.lat,
              start_time: resItem.start_datetime,
              end_time: resItem.end_datetime,
            };
          });
          this.refreshBounds();
          this.store.windowStore.scrollToEl(this.searchBoxEl);
        }),
      )
      .catch(
        action('get items fail', () => {
          this.isLoading = false;
        }),
      );
  };

  @action.bound
  fetchListCountryCategory = () => {
    this.isLoading = true;
    const size = this.categoryPageSize;
    axios
      .get(EventsApis.eventCategoryTabs(1, size))
      .then(res => res.data)
      .then(
        action('fetch catgory success', ({ total, facets }) => {
          this.isLoading = false;
          this.totalCategory = total.value;

          this.eventByCountryCategory = facets.country_code;

          this.eventByCountryCategory = this.eventByCountryCategory.map(item => ({
            ...item,
            active: this.countryCode === item.key,
          }));
        }),
      )
      .catch(
        action('get items fail', () => {
          this.isLoading = false;
        }),
      );
  };

  @action.bound
  fetchListFilterTab = () => {
    const pagesize = this.categoryTopicPageSize;
    axios
      .get(EventsApis.eventCategoryTabs(pagesize, 1))
      .then(res => res.data)
      .then(
        action('fetch catgory tabs success', ({ facets }) => {
          this.eventListCategoryTabs = facets.category;
          this.eventListCategoryTabs = this.eventListCategoryTabs.map(item => ({
            ...item,
            active: this.catIds === item.key,
          }));
        }),
      )
      .catch(
        action('get items fail', error => {
          logger.log(error);
        }),
      );
  };

  @action.bound
  onChangeTabsEvent = key => {
    this.catIds = key;
    this.eventListCategoryTabs = this.eventListCategoryTabs.map(item => ({
      ...item,
      active: item.key === this.catIds,
    }));

    this.eventPage = 1;
    this.searchAndUpdateParams();
  };

  @action.bound
  onScrollEndOption = (event, callback) => {
    const { target } = event;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      callback();
    }
  };

  getStart = () => {
    return this.eventPageSize * (this.eventPage - 1);
  };

  refreshBounds = () => {
    if (!this.maps) return;

    const bounds = new this.maps.LatLngBounds();

    this.eventList.forEach(item => {
      bounds.extend(new this.maps.LatLng(item.lat, item.lng));
    });

    this.map.fitBounds(bounds);
  };

  setSearchBoxEl = el => {
    this.searchBoxEl = el;
  };
}
