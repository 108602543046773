import { routerStore } from './routerStore';
import { WhatStore } from './whatStore';
import { AutoCompleteStore } from './autoCompleteStore';
import { VietsearchStore } from './vietsearchStore';
import { EventPageStore } from '../routes/events/page/eventPageStore';
import { EntryPageStore } from '../routes/entries/page/entryPageStore';
import { EntryDetailStore } from '../routes/entries/detail/entryDetailStore';
import { EventDetailStore } from '../routes/events/detail/eventDetailStore';
import { windowStore } from './windowStore';
import { HomePageStore } from './homePageStore';
import { AuthStore } from './authStore';
import { AuthModalStore } from '../shared/components/auth/authModalStore';
import { LanguagesStore } from '../shared/components/languages/languagesStore';
import { ExpertPageStore } from '../routes/experts/page/expertPageStore';
import { UserPageStore } from '../routes/user/page/userPageStore';
import { UserModalStore } from '../routes/user/page/userModalStore';
import { UserCrudStore } from '../routes/user/page/userCrudStore';
import { MyEntriesStore } from '../routes/my-entries/myEntriesStore';
import { CategoryPageStore } from '../routes/category/page/categoryPageStore';
import { APIS } from './apis';

class Store {
  constructor() {
    this.routerStore = routerStore;
    this.windowStore = windowStore;
    this.whatInputStore = new AutoCompleteStore(
      APIS.autoCompleteWhat,
      data => data.suggestions.map(item => item.what),
      this,
    );
    this.placeInputStore = new AutoCompleteStore(
      APIS.autoCompletePlaces,
      data => data.suggestions.map(item => item.where),
      this,
    );

    this.categoryBusinessInputStore = new AutoCompleteStore(
      APIS.autoCompleteCategory,
      data =>
        data.categories.map(category => {
          return {
            id: category.id,
            name: category.names.vi,
            names: category.names,
            category,
          };
        }),
      this,
      'business',
    );

    this.categoryExpertInputStore = new AutoCompleteStore(
      APIS.autoCompleteCategory,
      data =>
        data.categories.map(category => {
          return {
            id: category.id,
            names: category.names,
            category,
          };
        }),
      this,
    );

    this.authStore = new AuthStore(this);
    this.vietsearchStore = new VietsearchStore(this);
    this.whatStore = new WhatStore(this);
    this.homepageStore = new HomePageStore(this);
    this.eventPageStore = new EventPageStore(this);
    this.entryPageStore = new EntryPageStore(this);
    this.entryDetailStore = new EntryDetailStore(this);
    this.vietEventDetailStore = new EventDetailStore(this);
    this.authModalStore = new AuthModalStore(this);
    this.languagesStore = new LanguagesStore(this);
    this.expertPageStore = new ExpertPageStore(this);
    this.userPageStore = new UserPageStore(this);
    this.userModalStore = new UserModalStore(this);
    this.userCrudStore = new UserCrudStore(this);
    this.myEntriesStore = new MyEntriesStore(this);
    this.categoryPageStore = new CategoryPageStore(this);
  }
}

export const store = new Store();

store.vietsearchStore.reaction();
store.eventPageStore.reaction();
store.vietEventDetailStore.reaction();
store.homepageStore.reaction(store);
store.languagesStore.reaction();
store.whatInputStore.reaction();
store.expertPageStore.reaction();
store.entryDetailStore.reaction();
store.userPageStore.reaction();
global.store = store;
