import styled from 'styled-components';
import { Form } from 'antd';
import React from 'react';
import { observer } from 'mobx-react';
import { Colors } from '../index';

export const FormStyled = styled(Form)`
  padding: 40px !important;
  max-width: 400px;
`;

export const FormItemStyled = styled(Form.Item)`
  margin-bottom: 10px !important;
  min-width: 300px;

  .ant-form-item-control {
    line-height: unset;
  }
`;

export const ErrorStyled = styled.div`
  color: ${Colors.red};
  margin-bottom: 5px;
`;

export const FaceBookBtn = styled.button`
  background: ${Colors.facebook};
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  color: ${Colors.white};
  cursor: pointer;
  margin-bottom: 10px;
`;

export const GoogleBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.white};
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
`;

export const FormItem = observer(({ children, data, ...props }) => {
  const showError = data.error;
  const isValidStatus = data.isValid ? 'success' : undefined;
  const validateStatus = showError ? 'error' : isValidStatus;
  const help = showError ? data.error : undefined;
  const hasFeedback = !!validateStatus;

  return (
    <FormItemStyled hasFeedback={hasFeedback} validateStatus={validateStatus} help={help} {...props}>
      {children}
    </FormItemStyled>
  );
});
