import React from 'react';
import styled from 'styled-components';
import { Colors } from '../styles/Color.styles';
import { H4 } from '../elements/H.styles';

const WrapperStyled = styled.div`
  border-radius: 10px;
  background: ${Colors.white};
`;

const TitleStyled = styled.div`
  padding: 10px;
  text-align: center;
  h4 {
    margin-bottom: 0;
  }
`;

export const BoardForm = ({ title, children }) => (
  <WrapperStyled>
    <TitleStyled>
      <H4>{title}</H4>
    </TitleStyled>
    {children}
  </WrapperStyled>
);
