import DATA_API from './data-api';
import { store } from '../store';

export const getDataAggregationCrawled = (type = 'd') =>
  DATA_API.get(`/aggregation_crawled_entry?type=${type}`, store.authStore.getUserAuthorizationHeaders());

export const getDataAggregationProcessed = (type = 'd') =>
  DATA_API.get(`/aggregation_processed_entry?type=${type}`, store.authStore.getUserAuthorizationHeaders());

export const getVietnamesDetection = data => DATA_API.post('/processor/viet-names', data)
