import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { InputAutoComplete } from '../index';
import { findTypeEnum } from '../../../store/vietsearchStore';

const findTypeIconMap = {
  [findTypeEnum.all]: 'question',
  [findTypeEnum.expert]: 'user',
  [findTypeEnum.business]: 'home',
};

export const InputWhatContainer = compose(
  inject(({ store: { whatInputStore, vietsearchStore } }) => {
    const [t] = useTranslation(['common']);
    return {
      value: whatInputStore.value,
      dataSource: whatInputStore.items,
      onChange: whatInputStore.onChange,
      onPressEnter: vietsearchStore.searchWithoutFacetsAndUpdateParams,
      icon: findTypeIconMap[vietsearchStore.types],
      placeholder: t(`searchPlaceHolders.${[vietsearchStore.types]}`),
    };
  }),
  observer,
)(InputAutoComplete);
