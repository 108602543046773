import { action, observable } from 'mobx';

class WindowStore {
  @observable fromScrollY = null;

  @observable toScrollY = null;

  @observable toggled = false;

  @action.bound
  setScrollY = toScrollY => {
    this.toggled = !this.toggled;
    this.fromScrollY = window.pageYOffset || document.documentElement.scrollTop;
    this.toScrollY = toScrollY;
  };

  @action.bound
  scrollToTop = () => {
    this.setScrollY(0);
  };

  @action.bound
  scrollToEl = el => {
    if (!el) return;

    const toScrollY = el.getBoundingClientRect().top + window.scrollY;
    this.setScrollY(toScrollY);
  };

  scrollElToTop = el => {
    if (!el) return;

    // eslint-disable-next-line no-param-reassign
    el.scrollTop = 0;
  };
}

export const windowStore = new WindowStore();
