import styled from 'styled-components';
import { Icon, Input, AutoComplete } from 'antd';

import { Colors } from '../styles/Color.styles';

export const AutoCompleteStyled = styled(AutoComplete)`
  display: block !important;

  .ant-input-affix-wrapper .ant-input-prefix {
    left: 8px;
  }

  .ant-select-selection__placeholder {
    margin-left: 40px !important;
  }
`;

export const SearchIconStyled = styled(Icon)`
  color: ${Colors.black1};
  font-weight: bold;
  font-size: 20px;
  background-color: #fff;
`;

export const SearchInputStyled = styled(Input)`
  .ant-input {
    padding-left: 35px !important;
    border-radius: 30px;
  }
`;
