import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';
import { InputAutoComplete } from '../index';
import { findTypeEnum } from '../../../store/vietsearchStore';

const findTypeIconMap = {
  [findTypeEnum.all]: 'question',
  [findTypeEnum.expert]: 'user',
  [findTypeEnum.business]: 'home',
};

export const InputBusinessContainer = compose(
  inject(({ store: { whatInputStore, vietsearchStore, categoryBusinessInputStore } }) => {
    const [t, i18n] = useTranslation(['common']);
    const items = categoryBusinessInputStore.items.map(cat => cat.names[i18n.language]);

    return {
      value: whatInputStore.value,
      dataSource: items,
      onChange: value => {
        categoryBusinessInputStore.onChange(value);
        whatInputStore.setValue(categoryBusinessInputStore.value);
      },
      onPressEnter: vietsearchStore.searchWithoutFacetsAndUpdateParams,
      icon: findTypeIconMap.business,
      placeholder: t(`searchPlaceHolders.business`),
    };
  }),
  observer,
)(InputAutoComplete);
