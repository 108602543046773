import React from 'react';
import GoogleMapReact from 'google-map-react';
import Supercluster from 'supercluster';
import { CONFIGS } from '../../index';

const MAX_CLUSTER_ZOOM = 8;
const MAX_ZOOM = 20;
const CLUSTER_RADIUS = 40;

const clusterFn = new Supercluster({
  minZoom: 0,
  maxZoom: MAX_CLUSTER_ZOOM,
  radius: CLUSTER_RADIUS,
  map: data => ({ data }),
});

export class GoogleMap extends React.Component {
  getClusters = (items, center, zoom, bounds) => {
    if (!bounds) return [];

    const features = items.map(item => ({
      data: item,
      type: 'Feature',
      geometry: { type: 'Point', coordinates: [item.lng, item.lat] },
    }));

    clusterFn.load(features);

    return clusterFn
      .getClusters([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat], zoom)
      .map(({ geometry: { coordinates }, properties, id, data }) => ({
        lng: coordinates[0],
        lat: coordinates[1],
        numPoints: properties ? properties.point_count : 1,
        id,
        data,
      }));
  };

  onChange = ({ center, zoom, bounds }) => {
    const { onCenterChange, onZoomChange, onBoundsChange } = this.props;
    onCenterChange(center);
    onZoomChange(zoom);
    onBoundsChange(bounds);
  };

  getNewZoom = () => {
    let { zoom } = this.props;

    zoom += 2;

    if (zoom >= MAX_ZOOM) {
      return MAX_ZOOM;
    }

    return zoom;
  };

  onChildClick = item => {
    const { onCenterChange, onZoomChange } = this.props;
    const { lat, lng } = item;
    const zoomOnClick = item.numPoints > 1;

    onCenterChange({ lat, lng });
    if (zoomOnClick) {
      onZoomChange(this.getNewZoom(zoomOnClick));
    }
  };

  render() {
    const { bootstrapURLKeys, render, items, center, zoom, bounds, onMapLoaded } = this.props;
    const clusters = this.getClusters(items, center, zoom, bounds);

    return (
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={bootstrapURLKeys}
        center={center}
        zoom={zoom}
        onChange={this.onChange}
        onGoogleApiLoaded={onMapLoaded}
      >
        {render(clusters, this.onChildClick)}
      </GoogleMapReact>
    );
  }
}

const UsCenter = { lat: 41.850033, lng: -87.6500523 };

GoogleMap.defaultProps = {
  bootstrapURLKeys: { key: CONFIGS.googleMapKey },
  center: UsCenter,
  zoom: 3,
};
