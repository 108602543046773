import React from 'react';
import { compose } from 'recompose';
import { inject } from 'mobx-react';
import { Translation, useTranslation } from 'react-i18next';
import { Container, Carousel, Board } from '../../index';
import { TopExpertCardStyled, TopExpertImgStyled, TitleStyled, WrapperStyled } from './TopExpert.styles';
import { findTypeEnum } from '../../../../store/vietsearchStore';

class TopExpertCarousel extends React.Component {
  renderItem = item => {
    const { onClick } = this.props;
    return (
      <TopExpertCardStyled key={item.id} onClick={() => onClick(item)}>
        <TopExpertImgStyled src={item.thumbnail} alt="" />
        <TitleStyled>
          <Translation>{(t, { i18n }) => item.names[i18n.language]}</Translation>
        </TitleStyled>
      </TopExpertCardStyled>
    );
  };

  render() {
    const { expertCategories } = this.props;
    return <Carousel items={expertCategories} renderItem={this.renderItem} />;
  }
}

export const TopExpertCarouselContainer = compose(
  inject(({ store: { homepageStore, vietsearchStore, languagesStore } }) => {
    return {
      expertCategories: homepageStore.expertCategories,
      onClick: item => {
        vietsearchStore.topCategory(item.id, item.names[languagesStore.locale], findTypeEnum.expert);
      },
    };
  }),
)(TopExpertCarousel);

export const TopExpert = ({ title }) => {
  const [t] = useTranslation(['home']);
  return (
    <WrapperStyled>
      <Container>
        <Board title={title || t('recommendation.topExpert')}>
          <TopExpertCarouselContainer />
        </Board>
      </Container>
    </WrapperStyled>
  );
};
