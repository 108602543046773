import { Breadcrumb as BraadcrumbAntd, Icon } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';

const itemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={route.path}>
      {route.iconName && <Icon type={route.iconName} style={{ paddingRight: 5 }} />}
      {route.breadcrumbName}
    </Link>
  );
};

export const Breadcrumb = ({ routes }) => (
  <BraadcrumbAntd style={{ marginBottom: '20px' }} itemRender={itemRender} routes={routes} />
);
