import React from 'react';
import { Button } from 'antd';
import { compose } from 'recompose';
import { inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Container } from '../index';
import {
  AdvertisementStyled,
  ContentStyled,
  TitleStyled,
  ReasonTextStyled,
  BenefitTextStyled,
} from './Advertisement.styles';
import { Url } from '../../index';

const Advertisement = ({ onSearchClick }) => {
  const [t] = useTranslation(['home']);
  return (
    <Container>
      <AdvertisementStyled>
        <ContentStyled>
          <TitleStyled>{t('vsExperience.title')}</TitleStyled>
          <ReasonTextStyled>{t('vsExperience.reason')}</ReasonTextStyled>
          <BenefitTextStyled>{t('vsExperience.reasonDetail')}</BenefitTextStyled>
          <Button type="primary" icon="search" onClick={onSearchClick}>
            {t('vsExperience.search')}
          </Button>
        </ContentStyled>
      </AdvertisementStyled>
    </Container>
  );
};

export const AdvertisementContainer = compose(
  inject(({ store: { routerStore } }) => ({
    onSearchClick: () => routerStore.push(Url.vietSearch),
  })),
)(Advertisement);
