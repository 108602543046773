import { action, observable, reaction, computed } from 'mobx';
import { isEmpty } from 'lodash';
import axios from 'axios';
import { logger, Url } from '../shared';
import { EntryApis } from '../routes/entries/api';

export const findTypeEnum = {
  all: 'all',
  expert: 'person',
  business: 'business',
};

export const statusEnum = {
  verified: 'verified',
  unverified: 'unverified',
  crawled: 'crawled',
};

export class VietsearchStore {
  prevPathname = null;

  @observable types = findTypeEnum.all;

  @observable category = undefined;

  @observable showMapOption = true;

  @observable countryCode = undefined;

  @observable countriesFacets = [];

  @observable categoriesFacets = [];

  @observable typesFacets = [];

  @observable totalCategoriesFacets = 0;

  @observable totalCountriesFacets = 0;

  @observable countriesFacetsSize = 50;

  @observable categoriesFacetsSize = 50;

  @observable sort = 'time';

  @observable order = 'desc';

  @observable status = 'all';

  constructor(store) {
    this.store = store;
  }

  reaction() {
    reaction(
      () => this.types,
      () => {
        this.searchAndUpdateParams();
      },
    );
    reaction(
      () => this.category,
      () => {
        this.searchAndUpdateParams();
      },
    );
    reaction(
      () => this.countryCode,
      () => {
        this.searchAndUpdateParams();
      },
    );
    reaction(
      () => this.sort,
      () => {
        this.searchAndUpdateParams();
      },
    );
    reaction(
      () => this.status,
      () => {
        this.searchAndUpdateParams();
      },
    );
    reaction(
      () => this.order,
      () => {
        this.searchAndUpdateParams();
      },
    );
    reaction(
      () => this.store.languagesStore.locale,
      locale => {
        if (locale) {
          this.store.whatStore.fetchWhat();
        }
      },
    );
  }

  @computed
  get showMap() {
    return this.showMapOption && !this.store.whatStore.isEmpty;
  }

  @computed
  get totalFilters() {
    let total = 0;

    if (this.types !== findTypeEnum.all) {
      total += 1;
    }

    if (this.category) {
      total += 1;
    }

    if (this.countryCode) {
      total += 1;
    }

    return total;
  }

  @action.bound
  reset = () => {
    this.store.whatStore.reset();
    this.store.whatInputStore.reset();
    this.store.placeInputStore.reset();
    this.store.categoryExpertInputStore.reset();
    this.store.categoryBusinessInputStore.reset();
    this.resetFacets();
  };

  @action.bound
  resetFacets = () => {
    this.category = undefined;
    this.countryCode = undefined;
    this.sort = null;
    this.order = null;
  };

  @action.bound
  clearFilter = () => {
    this.resetFacets();
  };

  @action.bound
  topCategory = (id, name, findType) => {
    this.store.routerStore.push({
      pathname: Url.vietSearch,
    });
    this.reset();
    this.setCategory(id, name);
    this.setFindType(findType);
    this.store.whatStore.fetchWhat();
    this.updateUrlSearchParamsIfOnVietsearch();
  };

  @action.bound
  setFindType = findType => {
    this.types = findType;
  };

  @action.bound
  setCategory = (category, name) => {
    this.category = category;
    // switch (this.types) {
    //   case findTypeEnum.expert:
    //     this.store.categoryBusinessInputStore.items = [
    //       {
    //         id: category,
    //         name,
    //       },
    //     ];
    //     break;
    //   case findTypeEnum.business:
    //     this.store.categoryBusinessInputStore.items = [
    //       {
    //         id: category,
    //         name,
    //       },
    //     ];
    //     break;
    // }
  };

  @action.bound
  setCountryCode = countryCode => {
    this.countryCode = countryCode;
  };

  @action.bound
  setSort = value => {
    this.sort = value.sort_by;
    this.setOrder(value.order_by);
  };

  @action.bound
  setOrder = type => {
    this.order = type;
  };

  @action.bound
  setStatus = status => {
    this.status = status;
  };

  @action.bound
  fetchCountriesFacets = () => {
    const size = this.countriesFacetsSize;
    const what = this.store.whatInputStore.value;
    const where = this.store.placeInputStore.value;
    const findTypes = this.types === findTypeEnum.all ? null : this.types;
    axios
      .get(EntryApis.entryCategoryTabs(what, where, findTypes, '', 1, size))
      .then(res => res.data)
      .then(
        action('fetch catgory success', ({ total, facets }) => {
          this.store.entryPageStore.isLoading = false;
          this.totalCountriesFacets = total.value;
          this.countriesFacets = facets.country_code;
          this.countriesFacets.forEach(item => {
            if (this.countryCode === item.key) {
              item.active = true;
            } else {
              item.active = false;
            }
          });
        }),
      )
      .then(() => {
        this.store.entryPageStore.isLoading = true;
      })
      .catch(
        action('get items fail', () => {
          this.isLoading = false;
        }),
      );
  };

  @action.bound
  fetchCategoriesFacets = () => {
    const pagesize = this.categoriesFacetsSize;
    const what = this.store.whatInputStore.value;
    const where = this.store.placeInputStore.value;
    const findTypes = this.types === findTypeEnum.all ? null : this.types;
    axios
      .get(EntryApis.entryCategoryTabs(what, where, findTypes, '', pagesize, 1))
      .then(res => res.data)
      .then(
        action('fetch catgory tabs success', ({ total, facets }) => {
          this.categoriesFacets = facets.category;
          this.totalCategoriesFacets = total.value;
          this.categoriesFacets.forEach(item => {
            if (this.category === item.key) {
              item.active = true;
            } else {
              item.active = false;
            }
          });
        }),
      )
      .catch(
        action('get items fail', error => {
          logger.log(error);
        }),
      );
  };

  @action.bound
  fetchTypesFacets = () => {
    const what = this.store.whatInputStore.value;
    const where = this.store.placeInputStore.value;
    const findTypes = this.types === findTypeEnum.all ? null : this.types;
    axios
      .get(EntryApis.entryCategoryTabs(what, where, findTypes, '', 1, 1))
      .then(res => res.data)
      .then(
        action('fetch catgory tabs success', ({ facets }) => {
          this.typesFacets = facets.types;

          this.typesFacets.forEach(item => {
            if (this.types === item.key) {
              item.active = true;
            } else {
              item.active = false;
            }
          });
        }),
      )
      .catch(
        action('get items fail', error => {
          logger.log(error);
        }),
      );
  };

  @action.bound
  loadMoreCountriesFacets = () => {
    this.countriesFacetsSize += this.countriesFacetsSize;
    this.fetchCountriesFacets();
  };

  @action.bound
  loadMoreCategoriesFacets = () => {
    this.categoriesFacetsSize += this.categoriesFacetsSize;
    this.fetchCategoriesFacets();
  };

  @action.bound
  onScrollEndOption = (event, callback) => {
    const { target } = event;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      callback();
    }
  };

  @action.bound
  fetchFacets = () => {
    this.fetchTypesFacets();
    this.fetchCountriesFacets();
    this.fetchCategoriesFacets();
  };

  @action.bound
  searchAndUpdateParams = () => {
    this.updateUrlSearchParams();
    this.store.whatStore.fetchWhat();
    this.fetchFacets();
  };

  @action.bound
  searchWithoutFacetsAndUpdateParams = () => {
    this.resetFacets();
    this.updateUrlSearchParams();
    this.store.whatStore.fetchWhat();
    this.fetchFacets();
  };

  @action.bound
  onshowMapOptionChanged = checked => {
    this.showMapOption = checked;
  };

  @action.bound
  navigateToVietsearch = () => {
    this.updateUrlSearchParams(false);
  };

  @action.bound
  updateUrlSearchParamsIfOnVietsearch = () => {
    if (this.store.routerStore.pathname === Url.vietSearch) {
      this.searchAndUpdateParams();
    }
  };

  isInputsEmpty = () => {
    return (
      isEmpty(this.store.whatInputStore.value) && isEmpty(this.store.placeInputStore.value) && isEmpty(this.category)
    );
  };

  updateUrlSearchParams = (replaced = true) => {
    // const updateRoute = replaced ? this.store.routerStore.replace : this.store.routerStore.push;
    //
    // updateRoute({
    //   pathname: Url.vietSearch,
    //   search: queryString.stringify({
    //     what: this.store.whatInputStore.value,
    //     where: this.store.placeInputStore.value,
    //     country_code: this.countryCode,
    //     category: this.category,
    //     types: this.types,
    //   }),
    // });
  };
}
