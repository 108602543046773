import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { A } from '../index';
import { LanguagesDropdownStyled, DownOutLined } from './MultiLanguages.styles';
import { languages } from './languagesStore';

const Languages = ({ locale, setLocale }) => {
  const [t] = useTranslation(['common']);
  return (
    <Menu>
      {languages.map((language, index) => (
        <Menu.Item key={index} onClick={() => setLocale(language)}>
          {t(`common:languages.${language}`)}
        </Menu.Item>
      ))}
    </Menu>
  );
};
const MultiLanguages = ({ locale, setLocale }) => {
  const [t] = useTranslation();
  return (
    <LanguagesDropdownStyled overlay={<Languages locale={locale} setLocale={setLocale} />}>
      <A className="ant-dropdown-link">
        {t(`common:language`)} <DownOutLined />
      </A>
    </LanguagesDropdownStyled>
  );
};

export const MultiLanguagesContainer = compose(
  inject(({ store: { languagesStore } }) => ({
    locale: languagesStore.locale,
    setLocale: languagesStore.setLocale,
  })),
  observer,
)(MultiLanguages);
