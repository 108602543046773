import React from 'react';
import { SearchIconStyled, SearchInputStyled, AutoCompleteStyled } from './InputAutoComplete.styles';

export const InputAutoComplete = ({ dataSource, value, onChange, onPressEnter, onSelect, placeholder, icon }) => (
  <AutoCompleteStyled
    size="large"
    allowClear
    backfill
    defaultActiveFirstOption={false}
    dataSource={dataSource}
    onChange={onChange}
    onSelect={onSelect}
    placeholder={placeholder}
    value={value}
  >
    <SearchInputStyled onPressEnter={onPressEnter} prefix={<SearchIconStyled type={icon} />} />
  </AutoCompleteStyled>
);

InputAutoComplete.defaultProps = {
  icon: 'search',
};
