import styled from 'styled-components';
import { Colors } from '../styles/Color.styles';

export const H2 = styled.h2`
  color: ${Colors.black1};
  font-weight: 700;
  font-size: 30px;
  line-height: 1.1em;
`;

export const H3 = styled.h3`
  font-size: 24px;
  font-weight: 700;
  line-height: 1.1em;
  color: ${Colors.black1};
`;

export const H4 = styled.h4`
  color: ${Colors.black1};
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 20px;
  font-weight: 600;
`;
