import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import { Switch } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const InputShowMapOption = ({ checked, onChange }) => {
  const [t] = useTranslation(['common']);
  return (
    <strong>
      {t('showMap')} <Switch checked={checked} onChange={onChange} />
    </strong>
  );
};

export const InputShowMapOptionContainer = compose(
  inject(({ store: { vietsearchStore } }) => ({
    checked: vietsearchStore.showMapOption,
    onChange: vietsearchStore.onshowMapOptionChanged,
  })),
  observer,
)(InputShowMapOption);
