import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Colors } from '../index';

const Body = styled.div`
  font-size: 16px;
  text-align: center;
  padding: 20px;
  font-weight: 600;
`;

const Construction = styled.div`
  color: ${Colors.black};
`;

export const PageUnderConstruction = () => {
  const [t] = useTranslation(['common'], { useSuspense: false });
  return (
    <Body>
      <Construction>{t('pageUnderConstruction')}</Construction>
      <div>{t('thankForVisiting')}</div>
    </Body>
  );
};
